import { render, staticRenderFns } from "./SignUpRouter.vue?vue&type=template&id=3234880b&scoped=true&"
import script from "./SignUpRouter.vue?vue&type=script&lang=js&"
export * from "./SignUpRouter.vue?vue&type=script&lang=js&"
import style0 from "./SignUpRouter.vue?vue&type=style&index=0&id=3234880b&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3234880b",
  null
  
)

export default component.exports