<template>
  <div>
    <span id="magny-helper-import-folder" @click="dialogVisible = true"></span>
    <el-dialog title="Import a file" :visible.sync="dialogVisible" :center="true" width="30%">
      <el-row v-loading="fileLoading" type="flex" justify="center" style="align-items:center;flex-direction: column;">
        <el-upload
          class="file-uploader"
          drag
          action=""
          :http-request="handleFileSuccess"
          :before-upload="beforeFileUpload"
          :show-file-list="false"
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">Drop file here or <em>click to upload</em></div>
          <div class="el-upload__tip" slot="tip" style="text-align: center;">
            Accepts: Docx or PDF files with a size less than 200MB
          </div>
        </el-upload>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
import mammoth from 'mammoth';
import { readFileAsync } from '../../utils';
import { Editor } from 'tiptap';
import gql from 'graphql-tag';

export default {
  name: 'ImportDocumentButton',
  components: {},
  data() {
    return {
      dialogVisible: false,
      fileLoading: false,
      fileTitle: '',
      proseMirrorJSON: { type: 'doc', content: [{ type: 'title' }, { type: 'paragraph' }] },
    };
  },
  methods: {
    createFile() {
      this.$apollo
        .mutate({
          mutation: gql`
            mutation createFile($content: String, $folderId: Int, $fileName: String) {
              createFile(content: $content, folderId: $folderId, fileName: $fileName) {
                id
                name
                content
                user {
                  id
                  firstName
                  lastName
                }
                updatedAt
              }
            }
          `,
          variables: {
            content: JSON.stringify(this.proseMirrorJSON),
            fileName: this.fileTitle,
            folderId: undefined,
          },
        })
        .then(({ data }) => {
          this.$router.push({ name: 'editor', params: { id: data.createFile.id } });
        });
    },
    async handleFileSuccess(res) {
      this.fileLoading = true;
      const {
        file,
        file: { name, type },
      } = res;

      this.fileTitle = name.substring(0, name.lastIndexOf('.')) || name;
      const fileBuffer = await readFileAsync(file);

      if (type.includes('pdf')) {
        this.proseMirrorJSON = await this.parsePdfIntoJSON(fileBuffer);
        this.fileLoading = false;
        return;
      } else {
        this.proseMirrorJSON = await this.parseDocxIntoJSON(fileBuffer);
        this.makeFirstSentenceTheTitle();
      }

      await this.createFile();
      this.fileLoading = false;
    },
    async parseDocxIntoJSON(fileBuffer) {
      const mammothRes = await mammoth.convertToHtml({ arrayBuffer: fileBuffer });
      const { value: html } = mammothRes;
      const editor = new Editor({
        content: html,
      });
      const proseJSON = editor.getJSON();
      editor.destroy();
      return proseJSON;
    },
    async parsePdfIntoJSON(fileBuffer) {
      return fileBuffer;
    },
    makeFirstSentenceTheTitle() {
      const jsonOfFirstSentence = this.proseMirrorJSON?.content?.find(json => json.content)?.content || undefined;

      if (!jsonOfFirstSentence) return;

      const titleWithFirstSentence = {
        type: 'title',
        content: jsonOfFirstSentence,
      };

      this.proseMirrorJSON?.content?.unshift(titleWithFirstSentence);
    },
    beforeFileUpload(file) {
      const isAcceptable = file.type.includes('officedocument') || file.type.includes('pdf');
      const isLt200M = file.size < 209715200;

      if (!isAcceptable) {
        this.$message.error('File must be in PDF or DOCX format!');
      }
      if (!isLt200M) {
        this.$message.error('Avatar picture size can not exceed 200MB!');
      }
      return isAcceptable && isLt200M;
    },
  },
};
</script>
