<template>
  <img name="balsa" style="width: 65px; height: 24px" class="logo-balsa-svg" src="../assets/images/icons/balsa.svg" />
</template>

<script>
import Icon from './Icon';
export default {
  components: { Icon },
};
</script>


