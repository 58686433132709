<template>
  <el-row>
    <AddToFolderDialog
      :dialogVisible="$store.getters.isMoveDialogOpen"
      class="no-print"
      @handler="$store.dispatch('toggleMoveDialog')"
      :file="File"
      v-if="!this.$apollo.queries.File.loading && $store.getters.isMoveDialogOpen"
    />
    <ExportFileDialog
      :dialogVisible="$store.getters.isExportFileDialogOpen"
      @handler="$store.dispatch('toggleExportFileDialog')"
      @export="exportHandler"
      class="no-print"
      :file="File"
      v-if="!this.$apollo.queries.File.loading && $store.getters.isExportFileDialogOpen"
    />
    <FilePermissionDialog
      :dialogVisible="$store.getters.isFilePermissionDialogOpen"
      @handler="$store.dispatch('toggleFilePermissionDialog')"
      class="no-print"
      :file="File"
      v-if="!this.$apollo.queries.File.loading && $store.getters.isFilePermissionDialogOpen"
    />
    <RemoveFileDialog
      :dialogVisible="$store.getters.isRemoveFileDialogOpen"
      @handler="$store.dispatch('toggleRemoveFileDialog')"
      class="no-print"
      :file="File"
      v-if="!this.$apollo.queries.File.loading && $store.getters.isRemoveFileDialogOpen"
    />
    <span id="cursorNameBar"></span>
    <div
      v-if="!this.$store.getters.isHistoryDialogOpen"
      class="editor balsa-container"
      style="margin-bottom:2%;position:relative;"
    >
      <el-row type="flex" class="no-print" justify="center" style="position:relative;">
        <editor-menu-bar
          :editor="editor"
          v-slot="{ commands, isActive }"
          v-if="
            !this.$apollo.queries.File.loading && File.hasWritePermission && !this.$store.getters.isHistoryDialogOpen
          "
        >
          <div class="menubar" :class="{ 'menubar-positionFixed': positionFixed }">
            <button class="menubar__button" :class="{ 'is-active': isActive.bold() }" @click="commands.bold">
              <icon name="bold" />
            </button>

            <button class="menubar__button" :class="{ 'is-active': isActive.italic() }" @click="commands.italic">
              <icon name="italic" />
            </button>

            <button class="menubar__button" :class="{ 'is-active': isActive.strike() }" @click="commands.strike">
              <icon name="strike" />
            </button>

            <button class="menubar__button" :class="{ 'is-active': isActive.underline() }" @click="commands.underline">
              <icon name="underline" />
            </button>

            <button class="menubar__button" :class="{ 'is-active': isActive.code() }" @click="commands.code">
              <icon name="code" />
            </button>

            <button class="menubar__button" :class="{ 'is-active': isActive.paragraph() }" @click="commands.paragraph">
              <icon name="paragraph" />
            </button>

            <button
              class="menubar__button"
              :class="{ 'is-active': isActive.heading({ level: 1 }) }"
              @click="commands.heading({ level: 1 })"
            >
              H1
            </button>

            <button
              class="menubar__button"
              :class="{ 'is-active': isActive.heading({ level: 2 }) }"
              @click="commands.heading({ level: 2 })"
            >
              H2
            </button>

            <button
              class="menubar__button"
              :class="{ 'is-active': isActive.heading({ level: 3 }) }"
              @click="commands.heading({ level: 3 })"
            >
              H3
            </button>

            <button
              class="menubar__button"
              :class="{ 'is-active': isActive.bullet_list() }"
              @click="commands.bullet_list"
            >
              <icon name="ul" />
            </button>

            <button
              class="menubar__button"
              :class="{ 'is-active': isActive.ordered_list() }"
              @click="commands.ordered_list"
            >
              <icon name="ol" />
            </button>

            <button
              class="menubar__button"
              :class="{ 'is-active': isActive.blockquote() }"
              @click="commands.blockquote"
            >
              <icon name="quote" />
            </button>

            <button
              :class="{ 'is-active': showConversation }"
              class="menubar__button"
              @click="commands.openConversationBox"
            >
              <icon name="annotation" />
            </button>

            <!-- <button
              class="menubar__button"
              :class="{ 'is-active': isActive.code_block() }"
              @click="commands.code_block"
            >
              <icon name="code" />
            </button>-->

            <button class="menubar__button" @click="commands.horizontal_rule">
              <icon name="hr" />
            </button>

            <button
              class="menubar__button"
              @click="
                () => {
                  isUndo = true;
                  commands.undo();
                }
              "
            >
              <icon name="undo" />
            </button>

            <button
              class="menubar__button"
              @click="
                () => {
                  isRedo = true;
                  commands.redo();
                }
              "
            >
              <icon name="redo" />
            </button>
            <button
              class="menubar__button"
              @click="commands.createTable({ rowsCount: 3, colsCount: 3, withHeaderRow: false })"
            >
              <icon name="table" />
            </button>

            <span v-if="isActive.table()">
              <button class="menubar__button" @click="commands.deleteTable">
                <icon name="delete_table" />
              </button>
              <button class="menubar__button" @click="commands.addColumnBefore">
                <icon name="add_col_before" />
              </button>
              <button class="menubar__button" @click="commands.addColumnAfter">
                <icon name="add_col_after" />
              </button>
              <button class="menubar__button" @click="commands.deleteColumn">
                <icon name="delete_col" />
              </button>
              <button class="menubar__button" @click="commands.addRowBefore">
                <icon name="add_row_before" />
              </button>
              <button class="menubar__button" @click="commands.addRowAfter">
                <icon name="add_row_after" />
              </button>
              <button class="menubar__button" @click="commands.deleteRow">
                <icon name="delete_row" />
              </button>
              <button class="menubar__button" @click="commands.toggleCellMerge">
                <icon name="combine_cells" />
              </button>
            </span>
            <button class="menubar__button" @click="showImagePrompt(commands.image)">
              <icon name="image" />
            </button>
            <button class="menubar__button" :class="{ 'is-active': isActive.todo_list() }" @click="commands.todo_list">
              <icon name="checklist" />
            </button>
          </div>
        </editor-menu-bar>
      </el-row>

      <editor-menu-bubble
        :editor="editor"
        class="no-print"
        :keep-in-bounds="keepInBounds"
        v-slot="{ commands, isActive, getMarkAttrs, menu }"
        v-if="!this.$apollo.queries.File.loading && File.hasWritePermission"
      >
        <div
          class="menububble"
          :class="{ 'is-active': menu.isActive }"
          :style="`left: ${menu.left}px; bottom: ${menu.bottom}px;`"
        >
          <form class="menububble__form" v-if="linkMenuIsActive" @submit.prevent="setLinkUrl(commands.link, linkUrl)">
            <input
              class="menububble__input"
              type="text"
              v-model="linkUrl"
              placeholder="https://"
              ref="linkInput"
              @keydown.esc="hideLinkMenu"
            />
            <button class="menububble__button" @click="setLinkUrl(commands.link, null)" type="button">
              <icon name="remove" />
            </button>
          </form>

          <button class="menububble__button" :class="{ 'is-active': isActive.bold() }" @click="commands.bold">
            <icon name="bold" />
          </button>
          <button class="menububble__button" :class="{ 'is-active': isActive.strike() }" @click="commands.strike">
            <icon name="strike" />
          </button>
          <template>
            <button
              class="menububble__button"
              @click="showLinkMenu(getMarkAttrs('link'))"
              :class="{ 'is-active': isActive.link() }"
            >
              <span>{{ isActive.link() ? 'Update Link' : '' }}</span>
              <icon name="link" />
            </button>
          </template>

          <button
            class="menububble__button"
            :class="{ 'is-active': isActive.heading({ level: 1 }) }"
            @click="commands.heading({ level: 1 })"
          >
            H1
          </button>
          <button
            class="menububble__button"
            :class="{ 'is-active': isActive.heading({ level: 2 }) }"
            @click="commands.heading({ level: 2 })"
          >
            H2
          </button>
          <button
            class="menububble__button"
            :class="{ 'is-active': isActive.bullet_list() }"
            @click="commands.bullet_list"
          >
            <icon name="ul" />
          </button>
          <button class="menububble__button" :class="{ 'is-active': isActive.italic() }" @click="commands.italic">
            <icon name="italic" />
          </button>

          <button class="menububble__button" :class="{ 'is-active': isActive.code() }" @click="commands.code">
            <icon name="code" />
          </button>
        </div>
      </editor-menu-bubble>

      <div
        v-if="!this.$apollo.queries.conversation.loading && conversation"
        :class="{ closed: !showConversation }"
        class="comment-balsa no-print"
      >
        <div v-for="(comment, index) in conversation.comments" :key="index">
          <el-row type="flex" justify="space-between">
            <el-row type="flex" align="middle">
              <Avatar
                :src="comment.user.profilePhoto"
                :firstName="comment.user.firstName"
                :lastName="comment.user.lastName"
              />
              <el-row style="margin-left:12px;  ">
                <span class="semi-medium-font medium-font-weight">
                  <span class="semi-medium-font actorName"
                    >{{ comment.user.firstName }} {{ comment.user.lastName }}</span
                  >
                </span>
                <el-row type="flex" align="middle">
                  <i class="el-icon-time" style="color: rgb(112, 129, 148);" />
                  <span class="semi-medium-font" style="color: rgb(112, 129, 148);font-size:11px;margin-left:4px;">{{
                    convertDate(comment.createdAt)
                  }}</span>
                </el-row>
              </el-row>
            </el-row>
            <el-row type="flex">
              <i
                class="el-icon-more cursor-pointer"
                style="font-size: 18px;color: rgb(112, 129, 148);    margin-top: 2px;"
              />
            </el-row>
          </el-row>
          <el-row style="margin-top:16px;">
            <p style="line-height:1.5;font-weight:500;" class="semi-medium-font actorName">{{ comment.text }}</p>
          </el-row>
          <el-row style="margin:16px 0">
            <Divider />
          </el-row>
        </div>

        <div v-if="conversation && conversation.comments.length === 0">
          <el-row type="flex" align="middle">
            <Avatar :src="myProfile.profilePhoto" :firstName="myProfile.firstName" :lastName="myProfile.lastName" />
            <el-row style="margin-left:12px;  ">
              <span class="semi-medium-font medium-font-weight">
                <span class="semi-medium-font actorName">{{ myProfile.firstName }} {{ myProfile.lastName }}</span>
              </span>
            </el-row>
          </el-row>
          <el-row style="margin:16px 0">
            <Divider />
          </el-row>
        </div>
        <el-form @submit.native.prevent="createComment()">
          <el-row type="flex">
            <el-input placeholder="Reply..." v-model="inputComment" size="small" style="font-size:11px;" />
            <el-button type="primary" size="mini" style="margin-left:8px;" native-type="submit">Comment</el-button>
          </el-row>
        </el-form>
      </div>
      <editor-content class="editor__content" id="balsa-editor" :editor="editor"></editor-content>
    </div>
    <div v-else>
      <div style="display:flex;flex-direction: column;">
        <span class="font-heavy width-100" style="font-size: 36px; margin-left: 28px;">History</span>
        <el-button
          style="margin-left: 28px; margin-bottom: 15px; max-width: 90px;"
          type="primary"
          @click="handleBackHistory()"
          v-if="this.$store.getters.isHistoryDialogOpen"
        >
          Back
        </el-button>
      </div>
      <div class="history-main-container">
        <div class="history-mode-container">
          <div
            v-html="historyHTML"
            class="editor__content history__mode"
            id="balsa_editor_history"
            ref="balsa_editor_history"
          ></div>
        </div>
        <div class="history-tab">
          <HistoryDialog
            :dialogVisible="$store.getters.isHistoryDialogOpen"
            @handler="$store.dispatch('toggleHistoryDialog')"
            class="no-print"
            :file="File"
            :editor="editor"
            :func="this.setHistory"
            v-if="!this.$apollo.queries.File.loading"
          />
        </div>
      </div>
    </div>
    <el-row
      type="flex"
      justify="space-between"
      class="balsa-editor-search no-print"
      :class="search ? 'show-search' : 'hide-search'"
    >
      <el-row type="flex" justify="center" style="width:20px;" class="expanse-search">
        <div
          style="height:53px;display:flex;justify-content:center;align-items:center;"
          class="cursor-pointer"
          @click="iconDown = !iconDown"
        >
          <i class="el-icon-caret-right" :class="iconDown ? 'make-icon-down' : ''" style="transition: all 0.2s;" />
        </div>
      </el-row>
      <div class="search" style="display:flex;flex-direction:column;  padding: 10px 0;">
        <div style="display:flex;align-items:center">
          <input
            ref="search"
            class="ctrl__f-search"
            @keydown.enter.prevent="editor.commands.find(searchTerm)"
            placeholder="Search …"
            style="width:252px;"
            type="text"
            v-model="searchTerm"
          />
          <el-button type="primary" plain @click="editor.commands.find(searchTerm)" size="small">Find</el-button>
          <i
            class="el-icon-close balsa-editor-search-close-icon cursor-pointer"
            @click="
              search = false;
              editor.commands.clearSearch();
              iconDown = false;
            "
          />
        </div>
        <div style="display:flex;margin-top:12px; padding-right:15px;  " :style="!iconDown ? 'display:none' : ''">
          <input
            @keydown.enter.prevent="editor.commands.replace(replaceWith)"
            class="ctrl__f-replace"
            placeholder="Replace …"
            type="text"
            v-model="replaceWith"
          />
          <el-button size="mini" type="primary" plain>Replace</el-button>
          <el-button size="mini" type="primary" plain>Replace All</el-button>
        </div>
      </div>
    </el-row>
    <div class="suggestion-list" v-show="showSuggestions" ref="suggestions">
      <template v-if="hasResults">
        <div
          v-for="(user, index) in filteredUsers"
          :key="user.id"
          class="suggestion-list__item"
          :class="{ 'is-selected': navigatedUserIndex === index }"
          @click="selectUser(user)"
        >
          {{ user.name }}
        </div>
      </template>
      <div v-else class="suggestion-list__item is-empty">No users found</div>
    </div>
  </el-row>
</template>

<script>
import moment from 'moment';
import Divider from './Divider.vue';
import Fuse from 'fuse.js';
import tippy from 'tippy.js';
import Icon from './Icon';
import { Editor, EditorContent, EditorMenuBubble, EditorMenuBar } from 'tiptap';
import AddToFolderDialog from './Dialogs/AddToFolderDialog';
import ExportFileDialog from './Dialogs/ExportFileDialog';
import FilePermissionDialog from './Dialogs/FilePermissionDialog';
import RemoveFileDialog from './Dialogs/RemoveFileDialog';
import Avatar from './Avatar.vue';
import Doc from './Editor/Components/Doc';
import _ from 'lodash';
import Title from './Editor/Components/Title';
import Iframe from './Editor/Components/Iframe';
import Comment from './Editor/Nodes/Comment';
import Image from './Editor/Nodes/Image';
import TodoItem from './Editor/Nodes/TodoItem';
import Collaboration from './Editor/Plugins/Collaboration';
import HistoryDialog from './Dialogs/HistoryDialog';
import { io } from 'socket.io-client';
import {
  Blockquote,
  CodeBlock,
  HardBreak,
  Heading,
  HorizontalRule,
  OrderedList,
  BulletList,
  ListItem,
  TodoList,
  Bold,
  Code,
  Italic,
  Link,
  Strike,
  Underline,
  History,
  Table,
  TableHeader,
  TableCell,
  TableRow,
  Placeholder,
  TrailingNode,
  Mention,
  Search,
  CodeBlockHighlight,
} from 'tiptap-extensions';
import gql from 'graphql-tag';
import TurnDown from 'turndown';
import javascript from 'highlight.js/lib/languages/javascript';
import css from 'highlight.js/lib/languages/css';
import { CONVERSATION_QUERY, MYPROFILE_QUERY } from '../queries';
import { tmpdir } from 'os';

export default {
  components: {
    Avatar,
    Divider,
    EditorContent,
    EditorMenuBubble,
    AddToFolderDialog,
    EditorMenuBar,
    ExportFileDialog,
    FilePermissionDialog,
    Icon,
    RemoveFileDialog,
    HistoryDialog,
  },
  unmounted() {
    console.log('removed');
  },
  data() {
    return {
      documentVersion: 0,
      isAddedPragraph: false,
      historyUndoRedo: [],
      isUndo: false,
      isRedo: false,
      historyUndoRedoIndex: -1,
      checkChangesForHistory: false,
      inputComment: '',
      cursors: {},
      parentFileId: -1,
      selectedConversationId: null,
      showConversation: false,
      firstTime: false,
      highlighted: false,
      iconDown: false,
      search: false,
      hasWritePermission: false,
      replaceWith: null,
      searchTerm: null,
      positionFixed: false,
      linkUrl: null,
      linkMenuIsActive: false,
      keepInBounds: true,
      firstTransactionPassed: false,
      placeholderH1: '',
      placeholderP: '',
      htmlData: '<h1></h1><p></p>',
      firstHtmlData: '',
      editor: null,
      query: null,
      socket: null,
      userCount: 1,
      indexHistoryArray: [],
      suggestionRange: null,
      filteredUsers: [],
      navigatedUserIndex: 0,
      insertMention: () => {},
      observer: null,
      historyHTML: '',
      historyJSON: '',
      participants: {},
      timer: null,
      historyOfOriginalVersion: [],
    };
  },
  props: {
    myProfile: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    hasResults() {
      return this.filteredUsers.length;
    },
    showSuggestions() {
      return this.query || this.hasResults;
    },
  },
  apollo: {
    File: {
      query: gql`
        query File($id: Int!, $inviteCode: String, $publicViewCode: String, $log: Boolean) {
          File(id: $id, inviteCode: $inviteCode, publicViewCode: $publicViewCode, log: $log) {
            id
            name
            content
            version
            cursorPosition
            isStarred
            stepHistory
            hasWritePermission
            user {
              id
              firstName
              lastName
            }
            contributors {
              user {
                id
                firstName
                lastName
              }
            }
            comments {
              id
              from
              to
              text
            }
          }
        }
      `,
      variables() {
        return {
          id: parseInt(this.$route.params.id),
          inviteCode: this.$route.params.inviteCode,
          publicViewCode: this.$route.params.publicViewCode,
          log: true,
        };
      },
      error(error) {
        console.error("We've got an error!", error.graphQLErrors[0].message);
      },
      result({ data }) {
        // assumes that value is the JSON value, keeps the cursor at the same position
        if (!this.editor) {
          this.onInit(data.File);
        }
        this.parentFileId = data.File.id;
        if (!data.File.hasWritePermission) {
          this.editor.setOptions({ editable: false });
          this.hasWritePermission = false;
        } else {
          this.hasWritePermission = true;
        }
        if (data.File.content && JSON.stringify(this.editor.getJSON()) !== data.File.content) {
          // a change as happened, update the content, cursor is at the start of the editor,
          // however, that is no big deal, assume it's a different content anyways.
          if (!this.firstTime) {
            this.editor.setContent(JSON.parse(data.File.content));
            this.firstTime = true;
            if (data.File.cursorPosition) {
              const state = this.editor.state;
              const tr = state.tr.setSelection(
                state.selection.constructor.near(state.tr.doc.resolve(data.File.cursorPosition)),
              );

              this.editor.view.dispatch(tr.scrollIntoView());
            }
          }
        }
      },
    },
    contributor: {
      query: gql`
        query contributor($inviteCode: String) {
          contributor(inviteCode: $inviteCode) {
            id
            permissionLevel
            email
            isAnon
            user {
              id
              email
            }
          }
        }
      `,
      skip() {
        return !this.$route.params.inviteCode;
      },
      variables() {
        return {
          inviteCode: this.$route.params.inviteCode,
        };
      },
      result({ data }) {
        if (data.contributor.permissionLevel !== 'READ_WRITE') {
          this.editor.setOptions({
            editable: false,
          });
        }
      },
    },
    conversation: {
      query: CONVERSATION_QUERY,
      skip() {
        return !this.showConversation && !this.selectedConversationId;
      },
      variables() {
        return {
          uuid: this.selectedConversationId,
        };
      },
    },
  },
  created() {
    window.addEventListener('keydown', this.handleSearch);
    window.addEventListener('scroll', this.handleScroll);
    let randText = this.randomPlaceHolder();
    this.placeholderH1 = randText.h1;
    this.placeholderP = randText.p;
  },
  mounted() {
    console.log(this.$refs['balsa-editor-history']);
    document.addEventListener('keydown', function(event) {
      if (event.ctrlKey && event.key === 'z') {
        this.isUndo = true;
      }
      if (event.ctrlKey && event.key === 'y') {
        this.isRedo = true;
      }
    });
  },
  beforeMount() {
    let functionSaveHistory = this.saveHistory;
    const condition = this.checkChangesForHistory || this.indexHistoryArray.length !== 0;
    window.onbeforeunload = function() {
      if (condition) console.log('here');
      functionSaveHistory();
    };
  },
  destroyed() {
    if (this.checkChangesForHistory || this.indexHistoryArray.length !== 0) this.saveHistory();
    window.removeEventListener('keydown', this.handleSearch);
    this.$store.dispatch('toggleHistoryDialog', false);
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    setHistory(html) {
      this.historyHTML = html;
    },
    saveHistory() {
      let splittedHTML = [],
        splittedFirstHTML = [];
      let isOpen = false;
      let tmp = '';
      this.firstHtmlData.split('').forEach(item => {
        if (item === '<' && !isOpen) {
          isOpen = true;
        } else if (item === '>' && isOpen) {
          isOpen = false;
        }
        tmp += item;
        if (!isOpen) {
          splittedFirstHTML.push(tmp);
          tmp = '';
        }
      });
      isOpen = false;
      tmp = '';
      this.htmlData.split('').forEach(item => {
        if (item === '<' && !isOpen) {
          isOpen = true;
        } else if (item === '>' && isOpen) {
          isOpen = false;
        }
        tmp += item;
        if (!isOpen) {
          splittedHTML.push(tmp);
          tmp = '';
        }
      });
      splittedHTML = splittedHTML.map((item, index) => {
        if (item.match(/<[^<>]+>/g) === null && this.indexHistoryArray.indexOf(index) !== -1) {
          item = '<mark>' + item + '</mark>';
        } else if (this.indexHistoryArray.indexOf(index) !== -1 && item.indexOf('</') === -1) {
          let htmlTag = '';
          item.split('').forEach(x => {
            htmlTag += x;
          });
          if (
            htmlTag
              .split('')
              .slice(0, 3)
              .join('') === '<hr'
          ) {
            item = item.replace('<hr', '<hr style="border:none;background-color:green;height:1px;"');
          } else if (htmlTag.slice(0, 5) === '<img ') {
            item = item.replace('<img', '<img style="border: 2px solid green"');
          }
        }
        return item;
      });

      let offset = 0;
      let lastIndex = -1;
      let copy = new Array(...splittedHTML);
      let last = -1;
      let temporary;
      for (let i = 0; i < this.historyOfOriginalVersion.length; i++) {
        let item = this.historyOfOriginalVersion[i];
        if (item.isDeleted) {
          if (splittedFirstHTML[i].match(/<[^<>]+>/g) === null) {
            splittedHTML = splittedHTML
              .slice(0, item.index)
              .concat([
                '<del><mark>' + splittedFirstHTML[i] + '</mark></del>',
                ...splittedHTML.slice(item.index, splittedHTML.length),
              ]);
          } else {
            if (
              splittedFirstHTML[i]
                .split('')
                .slice(0, 3)
                .join('') === '<hr'
            ) {
              splittedFirstHTML[i] = splittedFirstHTML[i].replace(
                '<hr',
                '<hr style="border:none;background-color:red;height:5px;"',
              );
            } else if (splittedFirstHTML[i].slice(0, 5) === '<img ') {
              splittedFirstHTML[i] = splittedFirstHTML[i].replace('<img', '<img style="border: 5px solid red"');
            }
            splittedHTML = splittedHTML
              .slice(0, item.index)
              .concat([splittedFirstHTML[i], ...splittedHTML.slice(item.index, splittedHTML.length)]);
          }
          lastIndex = item.index;
          offset++;
        } else {
        }
      }
      for (let i = 0; i < splittedHTML.length - 1; i++) {
        if (
          splittedHTML[i]
            .split('')
            .slice(-6)
            .join('') === '</del>' &&
          splittedHTML[i + 1]
            .split('')
            .slice(0, 5)
            .join('') === '<del>'
        ) {
          splittedHTML[i] = splittedHTML[i]
            .split('')
            .slice(0, splittedHTML[i].indexOf('</del>'))
            .join('');
          splittedHTML[i + 1] = splittedHTML[i + 1]
            .split('')
            .slice(5, splittedHTML[i + 1].length)
            .join('');
        }
      }
      for (let i = 0; i < splittedHTML.length - 1; i++) {
        if (
          splittedHTML[i]
            .split('')
            .slice(-7)
            .join('') === '</mark>' &&
          splittedHTML[i + 1]
            .split('')
            .slice(0, 6)
            .join('') === '<mark>'
        ) {
          splittedHTML[i] = splittedHTML[i]
            .split('')
            .slice(0, splittedHTML[i].indexOf('</mark>'))
            .join('');
          splittedHTML[i + 1] = splittedHTML[i + 1]
            .split('')
            .slice(6, splittedHTML[i + 1].length)
            .join('');
        }
      }
      this.$apollo.mutate({
        mutation: gql`
          mutation($fileId: Int!, $historyJSONContent: String!, $contentMarkedHtml: String!) {
            createHistory(
              fileId: $fileId
              historyJSONContent: $historyJSONContent
              contentMarkedHtml: $contentMarkedHtml
            ) {
              id
              historyJSONContent
            }
          }
        `,
        variables: {
          fileId: this.parentFileId,
          historyJSONContent: JSON.stringify(this.editor.getJSON()),
          contentMarkedHtml: splittedHTML.join(''),
        },
      });
    },
    handleRestoreHistory(json) {
      this.editor.setContent(json);
    },
    onInit(file) {
      if (!file.content) {
        file.content = `{"type":"doc","content":[{"type":"title"},{"type":"paragraph"}]}`;
      }
      this.socket = io(`${process.env.VUE_APP_SOCKETSERVER}/doc-${file.id}`, {
        path: '/socket.io',
        autoConnect: false,
        transports: ['websocket', 'polling'],
        auth: {
          token: `${localStorage.getItem('TOKEN')}`,
          userId: `${localStorage.getItem('USERID')}`,
          clientID: `${localStorage.getItem('USERID')}.doc-${file.id}`,
        },
      })
        .on('init', data => this.onInit(data))
        // send all updates to the collaboration extension
        .on('documentUpdated', data => {
          const docData = JSON.parse(data.documentUpdated);
          this.documentVersion = docData.version;
          this.updateFromSocket(docData);
          clearTimeout(this.timer);
          this.$store.dispatch('updateSavingState', true);

          this.timer = setTimeout(() => {
            this.$store.dispatch('updateSavingState', false);
          }, 1000);
        })
        // get count of connected users
        .on('getCount', count => this.setUserCount(count))
        // update Cursor position of collaborators
        .on('cursorUpdated', data => {
          const cursorData = JSON.parse(data.documentCursor);
          console.log(cursorData);
          this.editor.extensions.options.collaboration.updateCursors(cursorData);
          this.participants = cursorData.participants;
          this.$emit('updateSocketContributors', cursorData.participants);
        })
        .on('connect_error', err => {
          console.log('error', err);
        });

      if (this.$route.name !== 'readOnlyEditor') {
        this.socket.connect();
      }
      let historyOnlyDelete = this.historyOnlyDelete;
      let historyEditFirstandSecondRange = this.historyEditFirstandSecondRange;
      this.documentVersion = file.version;
      this.editor = new Editor({
        editable: this.$route.name !== 'readOnlyEditor',
        extensions: [
          new Comment({
            showConversationBox: this.showConversationBox,
            hideConversationBox: this.hideConversationBox,
            createConversation: this.createConversation,
          }),
          new CodeBlockHighlight({
            languages: {
              javascript,
              css,
            },
          }),
          new Doc(),
          new Iframe(),
          new Title(),
          new Blockquote(),
          new BulletList(),
          new CodeBlock(),
          new HardBreak(),
          new Image({
            uploadImage: this.uploadImage,
          }),
          new Heading({ levels: [1, 2, 3] }),
          new HorizontalRule(),
          new ListItem(),
          new OrderedList(),
          new TodoItem({
            nested: true,
          }),
          new TodoList(),
          new Bold(),
          new Code(),
          new Italic(),
          new Link(),
          new Strike(),
          new Underline(),
          new History(),
          new TrailingNode({
            node: 'paragraph',
            notAfter: ['paragraph'],
          }),
          new Placeholder({
            showOnlyCurrent: false,
            emptyNodeText: node => {
              if (node.type.name === 'title') {
                return this.placeholderH1;
              }
              return this.placeholderP;
            },
          }),
          new Table({
            resizable: true,
          }),
          new TableHeader(),
          new TableCell(),
          new TableRow(),
          new Search({
            disableRegex: false,
          }),
          new Mention({
            // a list of all suggested items
            items: () => [
              ...this.File.contributors.map(c => ({
                id: c.user.id,
                name: [c.user.firstName, c.user.lastName].join(' '),
              })),
              {
                id: this.File.user.id,
                name: [this.File.user.firstName, this.File.user.lastName].join(' '),
              },
            ],
            // is called when a suggestion starts
            onEnter: ({ items, query, range, command, virtualNode }) => {
              this.query = query;
              this.filteredUsers = items;
              this.suggestionRange = range;
              this.renderPopup(virtualNode);
              // we save the command for inserting a selected mention
              // this allows us to call it inside of our custom popup
              // via keyboard navigation and on click
              this.insertMention = command;
            },
            // is called when a suggestion has changed
            onChange: ({ items, query, range, virtualNode }) => {
              this.query = query;
              this.filteredUsers = items;
              this.suggestionRange = range;
              this.navigatedUserIndex = 0;
              this.renderPopup(virtualNode);
            },
            // is called when a suggestion is cancelled
            onExit: () => {
              // reset all saved values
              this.query = null;
              this.filteredUsers = [];
              this.suggestionRange = null;
              this.navigatedUserIndex = 0;
              this.destroyPopup();
            },
            // is called on every keyDown event while a suggestion is active
            onKeyDown: ({ event }) => {
              // pressing up arrow
              if (event.keyCode === 38) {
                this.upHandler();
                return true;
              }
              // pressing down arrow
              if (event.keyCode === 40) {
                this.downHandler();
                return true;
              }
              // pressing enter
              if (event.keyCode === 13) {
                this.enterHandler();
                return true;
              }
              return false;
            },
            // is called when a suggestion has changed
            // this function is optional because there is basic filtering built-in
            // you can overwrite it if you prefer your own filtering
            // in this example we use fuse.js with support for fuzzy search
            onFilter: (items, query) => {
              if (!query) {
                return items;
              }
              const fuse = new Fuse(items, {
                threshold: 0.2,
                keys: ['name'],
              });
              return fuse.search(query);
            },
          }),
          this.$route.name !== 'readOnlyEditor'
            ? new Collaboration({
                version: this.documentVersion,
                clientID: `${localStorage.getItem('USERID')}.doc-${file.id}`,
                socket: this.socket,
                debounce: 250,
                me: {
                  displayname: '',
                  displaycolor: this.getDisplaycolor(localStorage.getItem('USERID')),
                },
              })
            : null,
        ],
        autoFocus: 'end',
        content: JSON.parse(file.content),
        onUpdate: ({ getJSON, getHTML, state, transaction }) => {
          this.historyJSON = JSON.stringify(getJSON());
          let pos = transaction.curSelection.$anchor.pos - 1;
          let isOpen = true;
          let tmp = '';
          let splittedHTML = [];
          getHTML()
            .replace(/&gt;/, '>')
            .replace(/&lt;/, '<')
            .split('')
            .forEach(item => {
              if (item === '<' && !isOpen) {
                isOpen = true;
              } else if (item === '>' && isOpen) {
                isOpen = false;
              }
              tmp += item;
              if (!isOpen) {
                splittedHTML.push(tmp);
                tmp = '';
              }
            });
          let splittedOldHTML = [];
          isOpen = true;
          tmp = '';
          this.htmlData
            .replace(/&gt;/, '>')
            .replace(/&lt;/, '<')
            .split('')
            .forEach(item => {
              if (item === '<' && !isOpen) {
                isOpen = true;
              } else if (item === '>' && isOpen) {
                isOpen = false;
              }
              tmp += item;
              if (!isOpen) {
                splittedOldHTML.push(tmp);
                tmp = '';
              }
            });
          let first =
            state.history$.prevRanges !== null
              ? state.history$.prevRanges.length !== 0
                ? state.history$.prevRanges[0]
                : splittedOldHTML.length > splittedHTML.length
                ? pos + 1
                : pos + 1 - (splittedHTML.length - splittedOldHTML.length)
              : splittedOldHTML.length > splittedHTML.length
              ? pos + 1
              : pos + 1 - (splittedHTML.length - splittedOldHTML.length);
          let second =
            state.history$.prevRanges !== null
              ? state.history$.prevRanges.length !== 0
                ? state.history$.prevRanges[1]
                : pos + 1
              : splittedOldHTML.length > splittedHTML.length
              ? pos + 1
              : pos + 1 + (splittedHTML.length - splittedOldHTML.length);

          let difference = splittedHTML.length - splittedOldHTML.length;
          let isDeleteFromFirstHTML = false,
            isAddtoFirstHTML = false;
          let moveCharGroup = false;
          let isShouldChange = false;
          if (
            (transaction.steps[0].gapFrom && !transaction.steps[transaction.steps.length - 1].gapFrom) ||
            (!transaction.steps[0].gapFrom && transaction.steps[transaction.steps.length - 1].gapFrom)
          ) {
            first = transaction.steps[transaction.steps[0].gapFrom ? 0 : transaction.steps.length - 1].from;
            let tmpRanges = historyEditFirstandSecondRange(splittedHTML, first, first);
            transaction.steps[transaction.steps.length - 1].to +
              (transaction.steps.length - 1) * 2 -
              (splittedOldHTML.length - splittedHTML.length);
            first = tmpRanges[0];
            isShouldChange = true;
            second =
              splittedHTML.length >= splittedOldHTML.length
                ? splittedHTML.slice(first, splittedHTML.length).indexOf('</ul>') !== -1
                  ? splittedHTML.slice(first, splittedHTML.length).indexOf('</ul>') + 1 + first
                  : splittedHTML.slice(first, splittedHTML.length).indexOf('</ol>') !== -1
                  ? splittedHTML.slice(first, splittedHTML.length).indexOf('</ol>') + 1 + first
                  : pos + 3
                : splittedOldHTML[first] === '<ul>'
                ? historyEditFirstandSecondRange(
                    splittedOldHTML,
                    transaction.steps[transaction.steps.length - 1].to,
                    transaction.steps[transaction.steps.length - 1].to,
                  )[0] +
                  (transaction.steps.length - 1) * 2 -
                  (splittedOldHTML.length - splittedHTML.length)
                : historyEditFirstandSecondRange(
                    splittedOldHTML,
                    transaction.steps[transaction.steps.length - 1].to +
                      (transaction.steps.length - 1) * 2 +
                      transaction.steps.length * 4 -
                      (splittedOldHTML.length - splittedHTML.length),
                    first,
                  )[0];
          } else if (transaction.steps[0].gapFrom) {
            first = transaction.steps[0].from;
            let tmpRanges = historyEditFirstandSecondRange(splittedHTML, first, first);
            first = tmpRanges[0];
            second =
              splittedHTML[first].indexOf('<ul') !== -1 || splittedHTML[first].indexOf('<ol') !== -1
                ? splittedHTML.slice(first, splittedHTML.length).indexOf('</ul>') !== -1
                  ? splittedHTML.slice(first, splittedHTML.length).indexOf('</ul>') + 1 + first
                  : splittedHTML.slice(first, splittedHTML.length).indexOf('</ol>') !== -1
                  ? splittedHTML.slice(first, splittedHTML.length).indexOf('</ol>') + 1 + first
                  : historyEditFirstandSecondRange(
                      splittedHTML,
                      transaction.steps[transaction.steps.length - 1].to,
                      transaction.steps[transaction.steps.length - 1].to,
                    )[0]
                : historyEditFirstandSecondRange(
                    splittedHTML,
                    transaction.steps[transaction.steps.length - 1].to,
                    transaction.steps[transaction.steps.length - 1].to,
                  )[0];
            isShouldChange = true;
            if (splittedOldHTML[first].indexOf('<ul') !== -1 && splittedHTML[first].indexOf('<ul') === -1) {
              if (first - second === difference) {
                second = first;
              } else {
                second += splittedHTML.length - splittedOldHTML.length;
              }
            }
          } else if (transaction.steps[0].mark) {
            let j = 0;
            first = transaction.steps[0].from;
            second = transaction.steps[transaction.steps.length - 1].to;
          } else if (transaction.steps.length === 2) {
            moveCharGroup = true;
          }
          if (!isShouldChange) {
            let ranges = historyEditFirstandSecondRange(splittedHTML, first, second);
            first = ranges[0];
            second = ranges[1];
          }
          if (second - first === 2 && splittedHTML[first] === '<p>' && splittedHTML[first + 1] === '</p>') {
            this.isAddedParagraph = true;
          }
          if (moveCharGroup && !this.isUndo && !this.isRedo) {
            let tmpFirst = transaction.steps[0].from;
            let tmpSecond =
              splittedHTML[transaction.steps[0].from].indexOf('<ul') !== -1 ||
              splittedHTML[transaction.steps[0].from].indexOf('<ol') !== -1
                ? splittedHTML.slice(transaction.steps[0].from, splittedHTML.length).indexOf('</ul>') !== -1
                  ? splittedHTML.slice(transaction.steps[0].from, splittedHTML.length).indexOf('</ul>') +
                    1 +
                    transaction.steps[0].from
                  : splittedHTML.slice(transaction.steps[0].from, splittedHTML.length).indexOf('</ol>') !== -1
                  ? splittedHTML.slice(transaction.steps[0].from, splittedHTML.length).indexOf('</ol>') +
                    1 +
                    transaction.steps[0].from
                  : transaction.steps[0].to
                : transaction.steps[0].to;
            if (this.isAddedPargaraph) {
              first = transaction.steps[1].from;
              second = transaction.steps[1].from + (transaction.steps[0].to - transaction.steps[0].from);
              this.isAddedPargaraph = false;
            }
            let tmpRanges = historyEditFirstandSecondRange(splittedOldHTML, tmpFirst, tmpSecond);
            historyOnlyDelete(tmpRanges[1] - tmpRanges[0], tmpRanges[0]);
          }
          if (splittedHTML[pos + 1] === '<hr>' && first === pos + 2 && second === first + 2) {
            first = pos + 1;
            second = pos + 2;
          }
          if (this.isUndo) {
            if (this.historyUndoRedoIndex !== 0) {
              let tmp = this.historyUndoRedo[this.historyUndoRedoIndex - transaction.steps.length];
              this.historyOfOriginalVersion = this.historyOfOriginalVersion.map((item, index) => {
                return tmp[0][index];
              });
              this.indexHistoryArray = tmp[1];
              this.historyUndoRedoIndex -= transaction.steps.length;
              this.isUndo = false;
            }
          } else if (this.isRedo) {
            if (this.historyUndoRedoIndex !== this.historyUndoRedo.length - 1) {
              let tmp = this.historyUndoRedo[this.historyUndoRedoIndex + transaction.steps.length];
              this.historyOfOriginalVersion = tmp[0];
              this.indexHistoryArray = tmp[1];
              this.historyUndoRedoIndex += transaction.steps.length;
              this.isRedo = false;
            }
          } else {
            if (first === 0 && second === 4 && getHTML() === '<h1></h1><p></p>') {
              this.historyOfOriginalVersion = this.historyOfOriginalVersion.map(item => {
                item.isDeleted = true;
                item.index += 4;
                return item;
              });
              this.checkChangesForHistory = true;
              this.indexHistoryArray = [];
            } else if (first === second) {
              historyOnlyDelete(splittedOldHTML.length - splittedHTML.length, first);
            } else if (second - first === difference || moveCharGroup) {
              this.historyOfOriginalVersion = this.historyOfOriginalVersion.map(item => {
                if (item.index >= first) {
                  item.index += second - first;
                  item.tmpIndex = item.index;
                }
                return item;
              });
              this.indexHistoryArray = this.indexHistoryArray
                .map(item => {
                  if (item >= first) {
                    return item + second - first;
                  }
                  return item;
                })
                .concat(Array.from({ length: second - first }, (_, i) => i + first))
                .sort(function(a, b) {
                  return a - b;
                });
            } else if (first < second) {
              difference = second - first - (splittedHTML.length - splittedOldHTML.length);
              historyOnlyDelete(difference, first);
              this.historyOfOriginalVersion = this.historyOfOriginalVersion.map(item => {
                if (item.index >= first) {
                  item.index += second - first;
                  item.tmpIndex = item.index;
                }
                return item;
              });
              this.indexHistoryArray = this.indexHistoryArray
                .map(item => {
                  if (item >= first) {
                    return item + second - first;
                  }
                  return item;
                })
                .concat(Array.from({ length: second - first }, (_, i) => i + first))
                .sort(function(a, b) {
                  return a - b;
                });
            }
            this.historyUndoRedo = this.historyUndoRedo.slice(0, this.historyUndoRedoIndex + 1);
            let tmpArray = [];
            this.historyOfOriginalVersion.forEach(item => {
              tmpArray.push({
                index: item.index,
                tmpIndex: item.tmpIndex,
                isDeleted: item.isDeleted,
                isChanged: item.isChanged,
              });
            });
            let tmpArray2 = [];
            this.indexHistoryArray.forEach(item => {
              tmpArray2.push(item);
            });
            this.historyUndoRedo.push([tmpArray, tmpArray2]);
            this.historyUndoRedoIndex++;
          }
          this.htmlData = getHTML();

          //   let splittedOldHTML2 = []
          //   isOpen = true
          //   tmp = ''
          //   this.firstHtmlData.replace(/&gt;/, '>').replace(/&lt;/, '<').split('').forEach(item => {
          //     if (item === '<' && !isOpen) {
          //       isOpen = true
          //     } else if (item === '>' && isOpen) {
          //       isOpen = false
          //     }
          //     tmp += item
          //     if (!isOpen) {
          //       splittedOldHTML2.push(tmp)
          //       tmp = ''
          //     }
          //   })
          //   console.log(splittedHTML.map((item,index) => `${index} ${item}\n`).join(''),'array')
          //   console.log(transaction,first,second,'array')
          //   console.log(this.indexHistoryArray,'array')
          //   console.log(this.historyOfOriginalVersion.map((item,isDeleted) => `${item.isDeleted} ${item.index} ${splittedOldHTML2[isDeleted]}\n`).join(''),'array')
        },
      });
      this.htmlData = this.editor.getHTML();
      this.firstHtmlData = this.htmlData;
      this.historyOfOriginalVersion = Array.from(
        { length: this.htmlData.replace(/<[^<>]+>/g, String.fromCharCode(17)).length },
        (_, i) => ({
          tmpIndex: i,
          index: i,
          isDeleted: false,
          isChanged: false,
        }),
      );
      let tmpArray = Array.from(
        { length: this.htmlData.replace(/<[^<>]+>/g, String.fromCharCode(17)).length },
        (_, i) => ({
          tmpIndex: i,
          index: i,
          isDeleted: false,
          isChanged: false,
        }),
      );
      let tmpArray2 = [];

      this.historyUndoRedo.push([tmpArray, tmpArray2]);

      this.historyUndoRedoIndex += 1;
    },
    handleSearch(e) {
      if ((e.which == '115' || e.which == '83' || e.which == '70') && (e.ctrlKey || e.metaKey)) {
        e.preventDefault();
        this.search = true;
        return false;
      }
    },
    getDisplaycolor(seed) {
      let color = Math.floor(Math.abs(Math.sin(seed) * 16777215));
      color = color.toString(16);
      // pad any colors shorter than 6 characters with leading 0s
      while (color.length < 6) {
        color = '0' + color;
      }

      return `#${color}`;
    },
    convertDate(createdAt) {
      return moment(parseInt(createdAt)).fromNow();
    },
    setUserCount(count) {
      this.userCount = count;
    },
    uploadImage(file) {
      return this.$apollo.mutate({
        mutation: gql`
          mutation($file: Upload) {
            uploadFile(file: $file) {
              id
              file
            }
          }
        `,
        variables: {
          file,
        },
      });
    },
    createConversation(_id) {
      this.$apollo.mutate({
        mutation: gql`
          mutation($fileId: Int!, $uuid: String!) {
            createConversation(fileId: $fileId, uuid: $uuid) {
              id
              uuid
            }
          }
        `,
        variables: {
          fileId: this.File.id,
          uuid: _id,
        },
      });
    },
    showConversationBox(_id) {
      this.selectedConversationId = _id;
      this.showConversation = true;
    },
    hideConversationBox(_id) {
      this.inputComment = '';
      if (this.showConversation && this.selectedConversationId) {
        if (this.conversation.comments.length === 0) {
          this.showConversation = false;
          this.selectedConversationId = null;
        } else {
          this.showConversation = false;
          this.selectedConversationId = null;
        }
      } else {
        this.showConversation = false;
        this.selectedConversationId = null;
      }
    },
    // navigate to the previous item
    // if it's the first item, navigate to the last one
    upHandler() {
      this.navigatedUserIndex = (this.navigatedUserIndex + this.filteredUsers.length - 1) % this.filteredUsers.length;
    },
    // navigate to the next item
    // if it's the last item, navigate to the first one
    downHandler() {
      this.navigatedUserIndex = (this.navigatedUserIndex + 1) % this.filteredUsers.length;
    },
    enterHandler() {
      const user = this.filteredUsers[this.navigatedUserIndex];
      if (user) {
        this.selectUser(user);
      }
    },
    // we have to replace our suggestion text with a mention
    // so it's important to pass also the position of your suggestion text
    selectUser(user) {
      this.insertMention({
        range: this.suggestionRange,
        attrs: {
          id: user.id,
          label: user.name,
        },
      });
      this.editor.focus();
      this.$apollo
        .mutate({
          mutation: gql`
            mutation($fileId: Int!, $userId: Int!) {
              mentionUser(fileId: $fileId, userId: $userId)
            }
          `,
          variables: {
            fileId: this.File.id,
            userId: user.id,
          },
        })
        .then(() => {})
        .catch(error => {
          console.log('MENTION ERROR', error);
        });
    },
    // renders a popup with suggestions
    // tiptap provides a virtualNode object for using popper.js (or tippy.js) for popups
    renderPopup(node) {
      if (this.popup) {
        return;
      }
      this.popup = tippy(node, {
        content: this.$refs.suggestions,
        trigger: 'mouseenter',
        interactive: true,
        theme: 'dark',
        placement: 'top-start',
        inertia: true,
        duration: [400, 200],
        showOnInit: true,
        arrow: true,
        arrowType: 'round',
      });
      // we have to update tippy whenever the DOM is updated
      if (MutationObserver) {
        this.observer = new MutationObserver(() => {
          this.popup.popperInstance.scheduleUpdate();
        });
        this.observer.observe(this.$refs.suggestions, {
          childList: true,
          subtree: true,
          characterData: true,
        });
      }
    },
    historyEditFirstandSecondRange(splittedHTML, first, second) {
      let offset = 0;
      let stack = [];
      let tagShouldCount = [
        '<tbody>',
        '</tbody>',
        '<u>',
        '</u>',
        '<div>',
        '</div>',
        '<strong>',
        '</strong>',
        '<em>',
        '</em>',
        '<s>',
        '</s>',
        '<code>',
        '</code>',
        '<span>',
        '</span>',
      ];

      let tagIndex = [];
      splittedHTML.forEach((item, index) => {
        if (item === '<table>') {
          stack.push(index);
        } else if (item === '</table>') {
          tagIndex.push([stack.pop(), index]);
        }
      });
      tagIndex = tagIndex.sort(function(a, b) {
        return a[0] - b[0];
      });
      let tmpSplittedHTML = new Array(...splittedHTML);
      for (let i = 0; i < tagIndex.length; i++) {
        let x = tagIndex[i];
        if (x[1] - x[0] === 45) {
          tmpSplittedHTML = tmpSplittedHTML
            .slice(0, x[1])
            .concat(tmpSplittedHTML.slice(x[1] + 1, tmpSplittedHTML.length));
          tagIndex.map(item => {
            if (item[0] > x[1]) {
              item[0] -= 1;
            }
            if (item[1] > x[1]) {
              item[1] -= 1;
            }
            return item;
          });
        }
      }
      tmpSplittedHTML = tmpSplittedHTML.map(item =>
        item.indexOf('<span') !== -1 ? '<span>' : item.indexOf('<div') !== -1 ? '<div>' : item,
      );

      let counter = 0;
      let isOffset = false;
      let firstCounter = first,
        secondCounter = second;
      for (let i = 0; i < tmpSplittedHTML.length; i++) {
        if (first !== second) {
          if (counter === first && tagShouldCount.indexOf(tmpSplittedHTML[i]) === -1) {
            firstCounter = i;
          }
          if (counter === second - 1 && tagShouldCount.indexOf(tmpSplittedHTML[i]) === -1) {
            secondCounter = i + 1;
            break;
          }
        } else {
          if (counter === first - 1 && tagShouldCount.indexOf(tmpSplittedHTML[i]) === -1) {
            firstCounter = i + 1;
            secondCounter = i + 1;
          }
        }
        if (tagShouldCount.indexOf(tmpSplittedHTML[i]) !== -1) isOffset = true;
        if (tagShouldCount.indexOf(tmpSplittedHTML[i]) === -1) {
          counter++;
        }
      }
      if (first === second) second = firstCounter;
      if (firstCounter !== secondCounter) {
        let array = [];
        for (let i = firstCounter - 1; tagShouldCount.includes(tmpSplittedHTML[i]); i--) array.push(i);

        first = firstCounter - array.length;
        second = secondCounter + array.length;
      } else if (isOffset) {
        second = secondCounter;
        first = firstCounter;
      }
      return [first, second];
    },
    historyOnlyDelete(difference, first) {
      let tmpHistoryOfOriginalVersion = new Array(
        ...this.historyOfOriginalVersion.map(item => {
          item.tmpIndex = item.index;
          return item;
        }),
      );
      for (let i = 0; i < tmpHistoryOfOriginalVersion.length; i++) {
        let item = tmpHistoryOfOriginalVersion[i];
        if (item.isDeleted) {
          for (let j = i; j < tmpHistoryOfOriginalVersion.length; j++) {
            tmpHistoryOfOriginalVersion[j].tmpIndex -= 1;
          }
        }
      }
      tmpHistoryOfOriginalVersion = tmpHistoryOfOriginalVersion.filter(item => !item.isDeleted);
      let rangeArray = Array.from({ length: difference }, (_, i) => i + first);
      let deletedRanges = [];
      tmpHistoryOfOriginalVersion = tmpHistoryOfOriginalVersion.map(item => {
        if (rangeArray.indexOf(item.tmpIndex) !== -1) {
          item.isDeleted = true;
          this.checkChangesForHistory = true;
          deletedRanges.push(rangeArray[rangeArray.indexOf(item.tmpIndex)]);
          rangeArray = rangeArray
            .slice(0, rangeArray.indexOf(item.tmpIndex))
            .concat(rangeArray.slice(rangeArray.indexOf(item.tmpIndex) + 1, rangeArray.length));
        }
        return item;
      });

      rangeArray = rangeArray.concat(deletedRanges).sort(function(a, b) {
        return a - b;
      });
      tmpHistoryOfOriginalVersion.forEach(item => {
        if (item.isDeleted) {
          this.historyOfOriginalVersion[
            this.historyOfOriginalVersion.map(item => item.index).indexOf(item.index)
          ].isDeleted = true;
        }
      });
      rangeArray.reverse().forEach(item => {
        let index = this.indexHistoryArray.indexOf(item);
        if (index !== -1) {
          if (!deletedRanges.includes(item)) {
            this.historyOfOriginalVersion = this.historyOfOriginalVersion.map(x => {
              if (x.index > item) {
                x.index -= 1;
              }
              return x;
            });
            this.indexHistoryArray = this.indexHistoryArray
              .slice(0, index)
              .concat(this.indexHistoryArray.slice(index + 1, this.indexHistoryArray.length));
          }
        }
        this.indexHistoryArray = this.indexHistoryArray.map(x => {
          if (x > item) {
            return x - 1;
          }
          return x;
        });
      });
    },
    destroyPopup() {
      if (this.popup) {
        this.popup.destroy();
        this.popup = null;
      }
      if (this.observer) {
        this.observer.disconnect();
      }
    },
    handleScroll(event) {
      const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      if (scrollTop >= 70) {
        this.positionFixed = true;
      } else {
        this.positionFixed = false;
      }
    },
    showImagePrompt(command) {
      var input = document.createElement('input');
      input.type = 'file';
      input.accept = 'image/*';

      input.onchange = e => {
        // getting a hold of the file reference
        var file = e.target.files[0];

        if (file !== null) {
          command({ file });
        }
      };
      input.click();
    },
    createComment(variables) {
      this.$apollo
        .mutate({
          mutation: gql`
            mutation createComment($conversationUuid: String, $fileId: Int, $text: String) {
              createComment(conversationUuid: $conversationUuid, fileId: $fileId, text: $text) {
                id
              }
            }
          `,
          variables: {
            fileId: this.File.id,
            conversationUuid: this.selectedConversationId,
            text: this.inputComment,
          },
          refetchQueries: ['conversation'],
        })
        .then(({ data }) => {
          this.inputComment = '';
        });
    },
    showLinkMenu(attrs) {
      this.linkUrl = attrs.href;
      this.linkMenuIsActive = true;
      this.$nextTick(() => {
        this.$refs.linkInput.focus();
      });
    },
    hideLinkMenu() {
      this.linkUrl = null;
      this.linkMenuIsActive = false;
    },
    handleBackHistory() {
      this.$store.dispatch('toggleHistoryMode');
      this.$store.dispatch('toggleHistoryDialog');
    },
    setLinkUrl(command, url) {
      command({ href: url });
      this.hideLinkMenu();
      this.editor.focus();
    },
    randomPlaceHolder() {
      const texts = [
        { h1: 'Better late than never', p: 'Are we partying, yet?' },
        { h1: 'A cat has nine lives', p: 'Everyone knows this.' },
        { h1: 'Actions speak louder than words', p: 'But everything starts with a word or two.' },
        { h1: 'All’s well that ends well', p: 'Focus on what you plan.' },
        { h1: 'Always put your best foot forward', p: 'Go grab it, now.' },
        { h1: 'An apple a day keeps the doctor away', p: 'Take the red one.' },
        { h1: 'A picture is worth a thousand words', p: 'Learn biology with pictures.' },
        { h1: 'A thing begun is half done', p: 'We trust you.' },
      ];

      return texts[Math.floor(Math.random() * texts.length)];
    },
    exportHandler(type) {
      if (type === 'pdf') {
        this.exportToPdf();
      } else if (type === 'docx') {
        this.exportToDoc();
      } else {
        this.exportToMD();
      }
    },
    exportToPdf() {
      window.print();
    },
    exportToDoc() {
      const header =
        "<html xmlns:o='urn:schemas-microsoft-com:office:office' " +
        "xmlns:w='urn:schemas-microsoft-com:office:word' " +
        "xmlns='http://www.w3.org/TR/REC-html40'>" +
        "<head><meta charset='utf-8'><title>Export HTML to Word Document with JavaScript</title></head><body>";
      const footer = '</body></html>';
      const sourceHTML = header + this.htmlData + footer;

      const source = 'data:application/vnd.ms-word;charset=utf-8,' + encodeURIComponent(sourceHTML);
      const fileDownload = document.createElement('a');
      document.body.appendChild(fileDownload);
      fileDownload.href = source;
      fileDownload.download = 'document.doc';
      fileDownload.click();
      document.body.removeChild(fileDownload);
    },
    exportToMD() {
      const turndownService = TurnDown();
      const markdown = turndownService.turndown(document.getElementsByClassName('ProseMirror')[0]);

      const source = 'data:text/markdown; charset=UTF-8,' + encodeURIComponent(markdown);
      const fileDownload = document.createElement('a');
      document.body.appendChild(fileDownload);
      fileDownload.href = source;
      fileDownload.download = 'document.md';
      fileDownload.click();
      document.body.removeChild(fileDownload);
    },
    updateFromSocket(data) {
      if (data.steps) {
        this.editor.extensions.options.collaboration.updateCursors(data);
        this.editor.extensions.options.collaboration.update(data);
      }
    },
  },
  beforeDestroy() {
    if (this.editor) {
      this.editor.destroy();
    }
    if (this.socket) {
      this.socket.destroy();
    }
  },
};
</script>
<style lang="scss">
@import '../assets/sass/variables.scss';
@import '../assets/sass/cursors.scss';

.el-input--small input {
  font-size: 13px;
}

.editor__content table td,
.editor__content table th {
  border: 2px solid #c8c8c8;
}

.comment-balsa {
  opacity: 1;
  transition: all 0.4s;
  position: absolute;
  width: 282px;
  right: -30%;
  background-color: white;
  padding: 16px;
  border-radius: 4px;
  box-shadow: rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}

.closed {
  opacity: 0;
  right: -32%;
  transition: all 0.4s;
}

.comment-bg {
  background-color: #fffadc;
  font-size: inherit;
}

.comment-bg u {
  text-decoration: underline;
  text-decoration-color: #ffd20a;
}

.editor .comment-bg::after {
  position: absolute;
  right: -15px;
  content: url(../assets/images/icons/annotation.svg);
  height: 20px;
  width: 20px;
}

.menubar-positionFixed {
  position: fixed;
  transition: background 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, padding-top 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    padding-bottom 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  //box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  border-bottom: 1px solid #efefef;
  padding-bottom: 5px;
  padding-top: 5px;
  background-color: #ffffff;
  z-index: 1;
  width: 100%;
  top: 60px;
}

.editor *.is-empty:nth-child(1)::before {
  padding-left: 3px;
}

.editor *.is-empty:nth-child(1)::before,
.editor *.is-empty:nth-child(2)::before {
  content: attr(data-empty-text);
  float: left;
  color: #aaa;
  pointer-events: none;
  height: 0;
}

.editor__content {
  background: white;
  border-radius: 4px;
  padding: 0 80px;
  box-shadow: rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}

.ProseMirror {
  min-height: 125vh;
  padding-top: 75px;
  padding-bottom: 75px;
}

.trying {
  position: absolute;
  width: 50px;
  height: 50px;
  background-color: red;
}

.editor__content h1 {
  font-size: 31.3px;
  color: #1c4586;
  display: inline;
}

.editor__content h2 {
  font-size: 25.1px;
  color: #1c4586;
}

.editor__content h3 {
  font-size: 21.95px;
  color: #1c4586;
}

.editor__content p {
  font-size: 17.24px;
  color: #293543;
  letter-spacing: -0.2px;
}

.ProseMirror > p {
  margin-top: 16px;
}

.menubar {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 10px 0;
}

ul[data-type='todo_list'] {
  padding-left: 0;
}

li[data-type='todo_item'] {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.todo-checkbox {
  border: 1px solid #1c4586;
  height: 1.2em;
  width: 1.2em;
  box-sizing: border-box;
  margin-right: 20px;
  margin-top: 0;
  user-select: none;
  -webkit-user-select: none;
  cursor: pointer;
  border-radius: 0.2em;
  background-color: transparent;
  transition: 0.4s background;
}

.todo-content > p:last-of-type {
  font-weight: initial;
}

li[data-done='true'] > .todo-checkbox::after {
  content: '\e6da';
  color: white;
  top: 0px;
  left: 1.2px;
  font-weight: 600;
  font-size: 14px;
  position: relative;
}

.todo-content {
  flex: 1;

  > p:last-of-type {
    margin-bottom: 0;
  }

  > ul[data-type='todo_list'] {
    margin: 0.5rem 0;
  }
}

li[data-done='true'] {
  > .todo-content {
    > p {
      text-decoration: line-through;
    }
  }

  > .todo-checkbox {
    background-color: $color-black;
  }
}

li[data-done='false'] {
  text-decoration: none;
}

.balsa-editor-search {
  right: 0;
  position: fixed;
  top: 62px;
  background-color: white;
  padding-right: 12px;
  border-radius: 4px;
  z-index: 1;
  min-height: 48px;
  border: 1px solid #efefef;
  transition: all 0.2s;
}

.balsa-editor-search-close-icon {
  font-size: 16px;

  font-weight: 600;
  color: #5f6368;
}

.balsa-editor-search-close-icon:hover {
  color: #323333;
}

.hide-search {
  top: -10px;
}

//tiptap
.cursor.me {
  display: none;
  /*background-color: #F55;*/
}

.cursor.inactive {
  opacity: 0.5;
}

.cursor.me::after {
  display: none;
  border-color: inherit;
}

.cursor.inactive::after {
  opacity: inherit;
  border-color: inherit;
}

.cursor {
  /*background-color: #555;*/
  color: #fff;
  text-align: center;
  border-radius: 6px 6px 6px 0px;
  padding: 5px;
  margin-left: -4.5px;
  position: absolute;
  z-index: 1;
  bottom: 5px;
  left: -50%;
  opacity: 0.85;
  white-space: nowrap;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome and Opera */
}

.cursor::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 0%;
  border-width: 5px;
  border-style: solid;
  border-color: inherit;
  /*border-color: #555 transparent transparent transparent;*/
  color: transparent;
}

.ProseMirror-widget {
  position: absolute;

  width: 0.1px;
  /*border-style: solid;*/
}

.tooltip {
  display: inline;
}

//
.search {
  display: flex;
  flex-wrap: wrap;

  border-radius: 5px;

  input {
    border-radius: 4px;
    padding: 0.25rem;
    border: 1px solid #efefef;
    margin-right: 0.6rem;
    font: inherit;
    font-size: 0.8rem;
    width: 20%;
    flex: 1;
  }

  button {
    margin-right: 0.6rem;
  }
}

.find {
  background: rgba(255, 213, 0, 0.5);
  font-size: inherit;
}

.expanse-search {
  margin-right: 2px;
  width: 24px;
}

.expanse-search:hover {
  border-radius: 4px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  transition: all 0.2s;
}

.make-icon-down {
  transform: rotate(90deg);
}

.balsa-container {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 960px;
}

.mention {
  background: #d0e0e2;
  color: rgba($color-black, 0.6);
  font-size: 0.8rem;
  font-weight: bold;
  border-radius: 5px;
  padding: 0.2rem 0.5rem;
  white-space: nowrap;
}

.mention-suggestion {
  color: rgba($color-black, 0.6);
}

.suggestion-list {
  padding: 0.2rem;
  border: 2px solid rgba($color-black, 0.1);
  font-size: 0.8rem;
  font-weight: bold;

  &__no-results {
    padding: 0.2rem 0.5rem;
  }

  &__item {
    border-radius: 5px;
    padding: 0.2rem 0.5rem;
    margin-bottom: 0.2rem;
    cursor: pointer;

    &:last-child {
      margin-bottom: 0;
    }

    &.is-selected,
    &:hover {
      background-color: rgba($color-white, 0.2);
    }

    &.is-empty {
      opacity: 0.5;
    }
  }
}

.tippy-tooltip.dark-theme {
  background-color: $color-black;
  padding: 0;
  font-size: 1rem;
  text-align: inherit;
  color: $color-white;
  border-radius: 5px;

  .tippy-backdrop {
    display: none;
  }

  .tippy-roundarrow {
    fill: $color-black;
  }

  .tippy-popper[x-placement^='top'] & .tippy-arrow {
    border-top-color: $color-black;
  }

  .tippy-popper[x-placement^='bottom'] & .tippy-arrow {
    border-bottom-color: $color-black;
  }

  .tippy-popper[x-placement^='left'] & .tippy-arrow {
    border-left-color: $color-black;
  }

  .tippy-popper[x-placement^='right'] & .tippy-arrow {
    border-right-color: $color-black;
  }

  .iframe {
    &__embed {
      width: 100%;
      height: 15rem;
      border: 0;
    }

    &__input {
      display: block;
      width: 100%;
      font: inherit;
      border: 0;
      border-radius: 5px;
      background-color: rgba($color-black, 0.1);
      padding: 0.3rem 0.5rem;
    }
  }
}

pre {
  &::before {
    content: attr(data-language);
    text-transform: uppercase;
    display: block;
    text-align: right;
    font-weight: bold;
    font-size: 0.6rem;
  }

  code {
    .hljs-comment,
    .hljs-quote {
      color: #999999;
    }

    .hljs-variable,
    .hljs-template-variable,
    .hljs-attribute,
    .hljs-tag,
    .hljs-name,
    .hljs-regexp,
    .hljs-link,
    .hljs-name,
    .hljs-selector-id,
    .hljs-selector-class {
      color: #f2777a;
    }

    .hljs-number,
    .hljs-meta,
    .hljs-built_in,
    .hljs-builtin-name,
    .hljs-literal,
    .hljs-type,
    .hljs-params {
      color: #f99157;
    }

    .hljs-string,
    .hljs-symbol,
    .hljs-bullet {
      color: #99cc99;
    }

    .hljs-title,
    .hljs-section {
      color: #ffcc66;
    }

    .hljs-keyword,
    .hljs-selector-tag {
      color: #6699cc;
    }

    .hljs-emphasis {
      font-style: italic;
    }

    .hljs-strong {
      font-weight: 700;
    }
  }
}

.dark .menubar__button {
  color: rgb(172, 174, 175);
}

.dark .menubar__button.is-active {
  background-color: rgb(55, 61, 63);
}

.dark .menubar__button:hover {
  background-color: rgb(55, 61, 63);
}

.dark .balsa-editor-search {
  background-color: #373d3f;
  border: 1px solid #373d3f;
}

.dark .menubar-positionFixed {
  background-color: #303437;
  border-bottom: 1px solid #373d3f;
}

.dark .editor__content {
  background: #373d3f;
}

.dark .editor__content h1 {
  color: rgb(172, 174, 175);
}

.dark .editor__content table td,
.editor__content table th {
  border: 2px solid rgb(172, 174, 175);
}

.dark .ctrl__f-search,
.dark .ctrl__f-replace {
  background-color: rgba(33, 36, 38, 0.45);
  border: rgba(33, 36, 38, 0.45);
  font-weight: 600;
  opacity: 0.9;
  color: rgb(172, 174, 175);
}

.dark .ctrl__f-search::placeholder,
.dark .ctrl__f-replace::placeholder {
  opacity: 0.9;
  color: rgb(172, 174, 175);
}

del > mark {
  background-color: red;
}

del {
  text-decoration-color: white;
}

.editor__content a {
  color: #0a5394;
  cursor: pointer;
  text-decoration: underline;
}

p {
  line-height: 1.5;
}

li[data-done='true'] > .todo-checkbox {
  background-color: #1c4586;
}

li[data-type='todo_item'] {
  padding: 8px 0;
}

li[data-type='todo_item']:hover {
  background-color: #f5f5f5;
  border-radius: 4px;
  margin-left: -8px;
  padding-left: 8px;
}

.editor__content li > p {
  padding-left: 20px;
  font-weight: initial;
}

.el-icon-check:before {
  content: '';
}

.editor__content ul,
.editor__content ol {
  color: #1c4586;
  font-size: 17.24px;
  font-weight: 600;
}

.editor__content blockquote {
  border-left: 3px solid #1c4586;
  padding: 10px;
  background-color: #e5e5e5;
}

.history__mode {
  min-height: 120vh;
  padding-top: 50px !important;
}

.history-tab {
  width: 25%;
}

.history-main-container {
  display: flex;
  width: 100%;
  padding-left: 25px;
  height: 77vh;
}

.history-mode-container {
  overflow-y: scroll;
  width: 75%;
}

// idk why this is needed but it brokes the LuckySheet
// td {
//   height: 30px !important;
// }
</style>
