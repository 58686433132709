<template>
  <el-row>
    <div class="balsa-divider"/>
  </el-row>
</template>

<script>
export default {};
</script>

