<template>
  <div class="card">
    <div class="card-header">
      <div class="card-header-profile">
        <Avatar
          size="smallAvatar"
          :firstName="activeUser.firstName"
          :lastName="activeUser.lastName"
          :src="activeUser.profilePhoto"
        />
        <div class="card-header-title">{{ activeUser.firstName + ' ' + activeUser.lastName }}</div>
      </div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="currentColor"
        class="cross"
        @click="setActiveUser(null)"
      >
        <path
          fill-rule="evenodd"
          d="M12 10.586l4.95-4.95a.75.75 0 011.06 1.06l-4.95 4.95 4.95 4.95a.75.75 0 11-1.06 1.06l-4.95-4.95-4.95 4.95a.75.75 0 01-1.06-1.06l4.95-4.95-4.95-4.95a.75.75 0 111.06-1.06L12 10.586z"
          clip-rule="evenodd"
        />
      </svg>
    </div>
    <div class="chat-container">
      <div class="no-messages" v-if="messages.length === 0">
        There is no messages yet. Leave a message for create new conversation.
      </div>
      <div class="chat-area">
        <div
          v-for="message in messages"
          :key="message.id"
          class="chat-message-container"
          :class="message.sender.id === myProfile.id ? 'local' : 'remote'"
        >
          <div v-if="message.type === 'SHEETFILE'" style="width: 100%;">
            <div
              class="chat-message link"
              @click="toFile('spreadsheet', message.file.id)"
              style="color: white;background-color: rgb(22, 179, 100);"
            >
              <div class="message-author">
                {{
                  message.sender.id === myProfile.id ? 'Me' : message.sender.firstName + ' ' + message.sender.lastName
                }}
              </div>
              <div class="chat-message-file-container">
                <BalsaIcon icon="spreadsheet.png" />
                <span class="chat-message-file-title">{{ message.file.name }}</span>
              </div>
            </div>
            <div class="chat-message" v-if="message.message" style="color: white;background-color: rgb(22, 179, 100);">
              <div class="chat-message-text">
                {{ message.message }}
              </div>
            </div>
            <div class="chat-message-time">
              {{ convertDate(message.createdAt) }}
            </div>
          </div>
          <div v-if="message.type === 'DOCFILE'" style="width: 100%;">
            <div
              class="chat-message link"
              @click="toFile('editor', message.file.id)"
              style="color: white;background-color: rgb(0 122 255);"
            >
              <div class="message-author">
                {{
                  message.sender.id === myProfile.id ? 'Me' : message.sender.firstName + ' ' + message.sender.lastName
                }}
              </div>
              <div class="chat-message-file-container">
                <BalsaIcon icon="newFile2.png" />
                <span class="chat-message-file-title">{{ message.file.name }}</span>
              </div>
            </div>
            <div class="chat-message" v-if="message.message" style="color: white;background-color: rgb(0 122 255);">
              <div class="chat-message-text">
                {{ message.message }}
              </div>
            </div>
            <div class="chat-message-time">
              {{ convertDate(message.createdAt) }}
            </div>
          </div>
          <div v-if="message.type === 'TEXT'" style="width: 100%;">
            <div class="chat-message">
              <div class="message-author">
                {{
                  message.sender.id === myProfile.id ? 'Me' : message.sender.firstName + ' ' + message.sender.lastName
                }}
              </div>
              <div class="chat-message-text">{{ message.message }}</div>
            </div>
            <div class="chat-message-time">
              {{ convertDate(message.createdAt) }}
            </div>
          </div>
        </div>
        <div id="chat-bottom-ref" />
      </div>
    </div>
    <FileAttachmentPreview
      v-if="filePreview.visible"
      :type="filePreview.type"
      :title="filePreview.title"
      :content="content"
      :removeFile="removeFile"
    />
    <div id="dummy-luckysheet-for-message" style="display: none;" />
    <div class="search-area">
      <input v-model="input" placeholder="Write something..." @keyup.enter="sendMessage" />
      <input
        style="display: none;"
        id="file-attachment"
        name="file-attachment"
        type="file"
        accept=".xlsx,.docx,.pdf"
        @change="beforeFileUpload"
      />
      <el-popover ref="popover" placement="top" trigger="click" v-model="visible">
        <div class="el-scrollbar__view el-select-dropdown__list">
          <div
            @click="
              visible = !visible;
              handleDialogVisible(true);
            "
            class="el-select-dropdown__item file-dropdown-item"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              style="width: 18px; height: 18px; margin-right: 10px;"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
              />
            </svg>
            Send from Dovl
          </div>
          <label for="file-attachment" @click="visible = !visible" class="el-select-dropdown__item file-dropdown-item">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              style="width: 18px; height: 18px; margin-right: 10px;"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5m-13.5-9L12 3m0 0l4.5 4.5M12 3v13.5"
              />
            </svg>
            Upload from Computer
          </label>
        </div>
      </el-popover>
      <div class="attachment-icon-container" v-popover:popover>
        <svg class="attachment-icon" focusable="false" aria-hidden="true" viewBox="0 0 24 24">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none">
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="m21.1525 10.8995-9.0156 9.0156c-2.0503 2.0502-5.3744 2.0502-7.4247 0-2.0502-2.0503-2.0502-5.3744 0-7.4247l9.0157-9.0156c1.3668-1.3668 3.5829-1.3668 4.9497 0 1.3668 1.3669 1.3668 3.583 0 4.9498l-8.662 8.662c-.6835.6835-1.7915.6835-2.475 0-.6833-.6834-.6833-1.7914 0-2.4748l7.6015-7.6014"
            ></path>
          </svg>
        </svg>
      </div>
      <div class="send-icon-container" @click="sendMessage">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="send-icon">
          <path
            d="M3.478 2.405a.75.75 0 00-.926.94l2.432 7.905H13.5a.75.75 0 010 1.5H4.984l-2.432 7.905a.75.75 0 00.926.94 60.519 60.519 0 0018.445-8.986.75.75 0 000-1.218A60.517 60.517 0 003.478 2.405z"
          />
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
import gql from 'graphql-tag';
import Avatar from '../Avatar.vue';
import moment from 'moment';
import LuckyExcel from 'luckyexcel';
import BalsaIcon from '../BalsaIcon.vue';
import mammoth from 'mammoth';
import { readFileAsync } from '../../utils';
import { Editor } from 'tiptap';
import FileAttachmentPreview from './FileAttachmentPreview.vue';

export default {
  name: 'ActiveChat',
  data() {
    return {
      input: '',
      content: null,
    };
  },
  props: {
    activeUser: {
      type: Object,
    },
    setActiveUser: {
      type: Function,
    },
    activeChatRoom: {
      type: Object,
    },
    messages: {
      type: Array,
    },
    myProfile: {
      type: Object,
    },
    filePreview: {
      type: Object,
    },
    handleFilePreview: {
      type: Function,
    },
    setActiveChatRoom: {
      type: Function,
    },
    setMessages: {
      type: Function,
    },
    dialogVisible: {
      type: Boolean,
    },
    selectedFileFromDovl: {
      type: Object,
    },
    handleDialogVisible: {
      type: Function,
    },
    handleSelectedFileFromDovl: {
      type: Function,
    },
  },
  components: { Avatar, BalsaIcon, BalsaIcon, FileAttachmentPreview },
  methods: {
    convertDate(createdAt) {
      return moment(parseInt(createdAt)).fromNow();
    },
    createChatRoom() {
      this.$apollo
        .mutate({
          mutation: gql`
            mutation($userIds: [ID!]) {
              createChatRoom(userIds: $userIds) {
                id
                createdAt
                updatedAt
                users {
                  id
                  email
                  firstName
                }
                messages {
                  id
                }
              }
            }
          `,
          variables: {
            userIds: this.activeUser.id,
          },
          refetchQueries: ['allChatRooms'],
        })
        .then(res => {
          this.setActiveChatRoom(res.data.createChatRoom);
        })
        .then(() => {
          this.sendMessage();
        });
    },
    createSpreadsheet() {
      this.$apollo
        .mutate({
          mutation: gql`
            mutation createSpreadsheet($fileName: String, $content: String) {
              createSpreadsheet(fileName: $fileName, content: $content) {
                id
                name
              }
            }
          `,
          variables: {
            content: JSON.stringify(this.content.data),
            fileName: this.content.fileName,
          },
          refetchQueries: ['allFolders'],
        })
        .then(({ data }) => {
          this.content = null;
          this.sendMessage(null, data.createSpreadsheet, 'SHEETFILE');
        });
    },
    createDocument() {
      this.$apollo
        .mutate({
          mutation: gql`
            mutation createFile($content: String, $folderId: Int, $fileName: String) {
              createFile(content: $content, folderId: $folderId, fileName: $fileName) {
                id
                name
                content
                user {
                  id
                  firstName
                  lastName
                }
                updatedAt
              }
            }
          `,
          variables: {
            content: JSON.stringify(this.content.data),
            fileName: this.content.fileName,
            folderId: undefined,
          },
          refetchQueries: ['allFolders'],
        })
        .then(({ data }) => {
          this.content = null;
          this.sendMessage(null, data.createFile, 'DOCFILE');
        });
    },
    sendMessage(...args) {
      const file = args[1];
      const fileType = args[2];

      if (!this.input && !this.content && !this.selectedFileFromDovl && !file) {
        return;
      }

      if (!this.activeChatRoom) {
        return this.createChatRoom();
      }

      if (this.content && !this.selectedFileFromDovl) {
        if (this.content.type === 'SHEETFILE') return this.createSpreadsheet();
        if (this.content.type === 'DOCFILE') return this.createDocument();
      }

      return this.$apollo
        .mutate({
          mutation: gql`
            mutation($chatRoomId: ID!, $type: MessageType, $fileId: ID, $message: String) {
              createMessage(chatRoomId: $chatRoomId, type: $type, fileId: $fileId, message: $message) {
                id
                createdAt
                message
                type
                sender {
                  id
                  firstName
                  email
                }
                chatRoom {
                  id
                }
                fileId
                file {
                  id
                  name
                }
              }
            }
          `,
          variables: {
            chatRoomId: this.activeChatRoom.id,
            message: this.input,
            fileId: (file && file.id) || (this.selectedFileFromDovl && this.selectedFileFromDovl.id),
            // type: file ? fileType : 'TEXT',
            type: file ? fileType : (this.selectedFileFromDovl && this.selectedFileFromDovl.fileType) || 'TEXT',
          },
          refetchQueries: ['allChatRooms'],
        })
        .then(res => {
          const { createMessage } = res.data;

          this.input = '';
          this.content = null;
          this.handleFilePreview({
            type: null,
            title: null,
            visible: false,
          });
          this.handleSelectedFileFromDovl(null);
          this.setMessages([...this.messages, createMessage]);
        })
        .then(() => {
          const chatBottomRef = document.getElementById('chat-bottom-ref');
          if (chatBottomRef) {
            chatBottomRef.scrollIntoView();
          }
        });
    },
    uploadExcel(fileName, file) {
      this.handleFilePreview({
        title: fileName,
        type: 'sheet',
        visible: true,
      });
      let _this = this;

      LuckyExcel.transformExcelToLucky(file, function(exportJson, luckysheetfile) {
        if (exportJson.sheets == null || exportJson.sheets.length == 0) {
          _this.handleFilePreview({
            title: 'Only support xlsx file',
            type: 'unsupported',
            visible: true,
          });
          return;
        }

        const data = exportJson.sheets[0].celldata;
        let maxR = -Infinity;
        let maxC = -Infinity;
        data.forEach(item => {
          if (item.r > maxR) {
            maxR = item.r;
          }
          if (item.c > maxC) {
            maxC = item.c;
          }
        });

        const numRows = Math.max(maxR + 1, 36);
        const numCols = Math.max(maxC + 1, 26);

        const result = Array.from({ length: numRows }, () => Array(numCols).fill(null));

        data.forEach(item => {
          const rowIndex = Math.min(item.r, numRows - 1);
          const colIndex = Math.min(item.c, numCols - 1);
          result[rowIndex][colIndex] = item.v;
        });

        _this.content = {
          fileName: exportJson.info.name.split('.')[0],
          data: result,
          type: 'SHEETFILE',
        };
      });

      document.getElementById('file-attachment').value = '';
    },
    removeFile() {
      this.content = null;
      this.handleFilePreview({
        type: null,
        title: null,
        visible: false,
      });
      this.handleSelectedFileFromDovl(null);
    },
    toFile(name, id) {
      this.$router.push({ name, params: { id } });
    },
    async parseDocxIntoJSON(fileName, fileBuffer) {
      this.handleFilePreview({
        title: fileName,
        type: 'doc',
        visible: true,
      });
      const mammothRes = await mammoth.convertToHtml({ arrayBuffer: fileBuffer });
      const { value: html } = mammothRes;
      const editor = new Editor({
        content: html,
      });
      const proseJSON = editor.getJSON();
      editor.destroy();
      return proseJSON;
    },
    async parsePdfIntoJSON(fileName, fileBuffer) {
      this.handleFilePreview({
        title: fileName,
        type: 'doc',
        visible: true,
      });

      return fileBuffer;
    },
    async beforeFileUpload(evt) {
      this.handleSelectedFileFromDovl(null);
      const files = evt.target.files;
      const file = files[0];
      const fileName = file.name;
      const suffixArr = fileName.split('.'),
        suffix = suffixArr[suffixArr.length - 1];

      if (files == null || files.length == 0) {
        this.handleFilePreview({
          title: 'No files wait for import',
          type: 'unsupported',
          visible: true,
        });
        return;
      }

      if (suffix !== 'xlsx' && suffix !== 'docx' && suffix !== 'pdf') {
        this.handleFilePreview({
          title: 'Only support xlsx, docx or pdf files.',
          type: 'unsupported',
          visible: true,
        });
        return;
      }

      const isLt200M = file.size < 209715200;

      if (!isLt200M) {
        this.handleFilePreview({
          title: 'File size can not exceed 200MB',
          type: 'unsupported',
          visible: true,
        });
        return;
      }

      if (suffix === 'xlsx') {
        return this.uploadExcel(fileName, file);
      }

      const fileBuffer = await readFileAsync(file);

      if (suffix === 'docx') {
        const parsedData = await this.parseDocxIntoJSON(fileName, fileBuffer);
        this.content = { fileName: fileName.split('.')[0], data: parsedData, type: 'DOCFILE' };
        return;
      }

      if (suffix === 'pdf') {
        const parsedData = await this.parsePdfIntoJSON(fileName, fileBuffer);
        this.content = { fileName: fileName.split('.')[0], data: parsedData, type: 'DOCFILE' };
        return;
      }

      return this.handleFilePreview({
        title: 'Only support xlsx, docx or pdf files.',
        type: 'unsupported',
        visible: true,
      });
    },
  },
  created() {
    this.$watch(
      () => this.messages,
      () => {
        this.$nextTick(() => {
          const chatBottomRef = document.getElementById('chat-bottom-ref');
          if (chatBottomRef) {
            chatBottomRef.scrollIntoView();
          }
        });
      },
    );
  },
  watch: {
    activeUser() {
      this.input = '';
    },
  },
};
</script>

<style scoped>
.cross {
  cursor: pointer;
  color: #000000;
  width: 24px;
  height: 24px;
}
.dark .cross {
  color: #ffffff;
}
.card {
  background: white;
  width: 390px;
  border: 1px solid #f1f2f4;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
}

.dark .card {
  background: #1a1a1a;
  border: 1px solid #2a2a2a;
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgb(242, 244, 247);
  padding: 12px;
}
.dark .card-header {
  border-bottom: 1px solid #2a2a2a;
}
.card-header-profile {
  display: flex;
  align-items: center;
  column-gap: 12px;
}
.card-header-title {
  font-size: 15px;
  font-weight: 600;
  color: rgb(17, 25, 39);
}

.dark .card-header-title {
  color: #ffffff;
}

.chat-container {
  height: 50vh;
  overflow-y: auto;
}
.chat-container::-webkit-scrollbar {
  width: 6px;
}

.chat-container::-webkit-scrollbar-thumb {
  background-color: #a7a7a7;
  border-radius: 6px;
}
.chat-area {
  padding: 12px;
  display: grid;
  flex-direction: column;
}

.no-messages {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 95%;
  color: rgb(107, 114, 128);
  font-size: 14px;
  font-weight: 400;
  padding: 0 16px;
}
.chat-message-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 12px;
  min-width: 92px;
  max-width: 80%;
}

.chat-message-container:last-child {
  margin-bottom: 0;
}

.chat-message {
  word-wrap: break-word;
  width: 100%;
  position: relative;
  padding: 8px 16px;
  margin-bottom: 2px;
  box-shadow: rgba(0, 0, 0, 0.04) 0px 5px 22px, rgba(0, 0, 0, 0.03) 0px 0px 0px 0.5px;
  border-radius: 20px;
  background-color: rgb(255, 255, 255);
  color: rgb(17, 25, 39);
}

.chat-message-file-container {
  padding: 8px 72px;
  display: flex;
  flex-direction: column;
}

.chat-message-file-title {
  text-align: center;
  font-size: 12px;
}

.link {
  cursor: pointer;
}

.message-author {
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 4px;
}

.remote {
  justify-self: flex-start;
}

.local {
  justify-self: flex-end;
}

.local .chat-message {
  color: rgb(255, 255, 255);
  background-color: rgb(22, 179, 100);
}

.remote .chat-message {
  color: rgb(17, 25, 39);
  background-color: rgb(255, 255, 255);
}

.dark .local {
  color: #ffffff;
  background-color: transparent;
}

.chat-message-text {
  font-size: 14px;
}

.local .chat-message-time {
  width: 100%;
  text-align: right;
  padding-right: 10px;
}

.remote .chat-message-time {
  text-align: left;
  padding-left: 10px;
}

.chat-message-time {
  font-size: 10px;
}

.search-area {
  display: flex;
  align-items: center;
  border-top: 1px solid rgb(242, 244, 247);
  padding: 0 1em;
  height: 55px;
  gap: 4px;
}
.dark .search-area {
  border-top: 1px solid #2a2a2a;
}
input {
  padding: 0 8px;
  width: 100%;
  height: 100%;
  outline: none;
  position: relative;
  color: #363636;
  font-size: 13px;
  background: transparent;
  border: 0;
  font-family: 'Inter', sans-serif;
  resize: none;
}
.dark input {
  color: #ffffff;
}

.send-icon-container,
.attachment-icon-container {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  border-radius: 50%;
}

.attachment-icon-container {
  padding: 10px;
}

.send-icon-container {
  padding: 8px;
  background-color: #33c871;
}

.send-icon-container:hover {
  background-color: #2daa66;
}

.send-icon {
  transform: rotate(-45deg);
  color: #fff;
  width: 20px;
  height: 20px;
}

.attachment-icon-container:hover {
  background-color: #f1f2f4;
}
.dark .attachment-icon-container:hover {
  background-color: #2a2a2a;
}
.attachment-icon-container:hover > .attachment-icon {
  color: #2daa66;
}

.attachment-icon {
  color: rgb(108, 115, 127);
  width: 22px;
  height: 22px;
}

/* .file-attachment-preview {
  height: 0;
  display: flex;
  align-items: center;
  padding: 0 12px;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  background-color: #33c871;
  color: #ffffff;
  font-weight: 400;
}

.file-attachment-preview > .file-attachment-title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 12px;
  color: #ffffff;
}

.file-attachment-preview > .file-attachment-icon {
  cursor: pointer;
  color: #ffffff;
  width: 22px;
  height: 22px;
  margin: 4px 0 4px auto;
} */

.file-dropdown-item {
  border-radius: 4px;
  padding: 0 12px;
  display: flex;
  align-items: center;
}
.dark .file-dropdown-item {
  color: #ffffff;
}
/* .file-attachment-preview-doc-icon,
.file-attachment-preview-sheet-icon {
  display: none;
  color: #ffffff;
  width: 20px;
  height: 20px;
  margin-right: 8px;
  margin-top: 4px;
  margin-bottom: 4px;
}
.visible-file-attachment-preview-doc-icon {
  display: block;
} */
</style>

<style>
.el-popover {
  padding: 4px;
  border-radius: 8px;
}

#luckysheetloadingdata {
  z-index: 1006 !important;
}

.luckysheetLoaderGif {
  display: none !important;
}
</style>
