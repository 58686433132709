<template>
  <div class="flex-justiify-center">
    <el-button size="small" type="primary">Invite</el-button>
  </div>
</template>

<script>
export default {};
</script>

<style  scoped>
.flex-justiify-center {
  display: flex;
  align-items: center;
}
.dashed-avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 37px;
  height: 37px;
  border-radius: 50%;
  border: 1px dashed #007aff;
}
.icon {
  margin-right: 0;
  color: #007aff;
}
</style>
