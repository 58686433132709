<template>
  <el-container>
    <EditorHeader :myProfile="myProfile" :socketContributorIds="socketContributorIds" />
    <Main class="sub-main balsa-header-bg">
      <LuckySheet :myProfile="myProfile" :handleUpdateSocketContributors="handleUpdateSocketContributors" />
    </Main>
  </el-container>
</template>

<script>
import { MYPROFILE_QUERY } from '../queries';
import EditorHeader from './Headers/EditorHeader.vue';
import LuckySheet from './LuckySheet.vue';
import Main from './Main.vue';
export default {
  name: 'SpreadsheetRouter',
  components: { EditorHeader, Main, LuckySheet },
  props: {
    msg: String,
  },
  data() {
    return {
      socketContributorIds: [],
    };
  },
  watch: {
    socketContributorIds() {
      console.log('socketContributorIds changed', this.socketContributorIds);
    },
  },
  methods: {
    handleUpdateSocketContributors(participantsJSON) {
      // console.log('handleUpdateSocketContributors', participantsJSON);
      // this.socketContributorIds = [1, 2, 3];
    },
  },
  apollo: {
    myProfile: {
      query: MYPROFILE_QUERY,
      skip() {
        return this.$route.name === 'readOnlyEditor';
      },
    },
  },
};
</script>

<style>
/* Lucysheet Lib's Devs broke copy button so i have to hide */
#luckysheet-copy-btn {
  display: none;
}
</style>
