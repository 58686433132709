<template>
  <div>
    <router-link id="magny-helper-home" :to="{ name: 'home', params: { activeNameState: 'first' } }" />
    <router-link id="magny-helper-folders" :to="{ name: 'home', params: { activeNameState: 'second' } }" />
    <router-link id="magny-helper-starred" :to="{ name: 'home', params: { activeNameState: 'third' } }" />
    <router-link id="magny-helper-recent" :to="{ name: 'home', params: { activeNameState: 'fourth' } }" />
  </div>
</template>

<script>
export default {};
</script>

<style scoped></style>
