<template>
  <div>
    <div id="chat-overlay">
      <ActiveChat
        v-if="activeUser"
        :dialogVisible="dialogVisible"
        :myProfile="myProfile"
        :messages="messages"
        :activeUser="activeUser"
        :selectedFileFromDovl="selectedFileFromDovl"
        :activeChatRoom="activeChatRoom"
        :setMessages="setMessages"
        :setActiveUser="setActiveUser"
        :setActiveChatRoom="setActiveChatRoom"
        :handleDialogVisible="handleDialogVisible"
        :handleSelectedFileFromDovl="handleSelectedFileFromDovl"
        :filePreview="filePreview"
        :handleFilePreview="handleFilePreview"
      />
      <ChatUsers
        v-if="!this.$apollo.queries.allChatRooms.loading"
        :myProfile="myProfile"
        :allChatRooms="allChatRooms"
        :activeUser="activeUser"
        :setActiveUser="setActiveUser"
        :setMessages="setMessages"
        :setActiveChatRoom="setActiveChatRoom"
        :toggleMessageListCard="toggleMessageListCard"
      />
    </div>
    <el-dialog
      title="Select a file to send"
      custom-class="small-dialog"
      class="myFiles-dropdown"
      :visible.sync="dialogVisible"
    >
      <div v-if="!this.$apollo.queries.myFiles.loading">
        <div :key="file.id" v-for="file in myFiles">
          <div class="myFiles-item" @click="handleSelectToUploadFile(file)">
            <BalsaIcon
              :icon="
                file.fileType === 'document' ? 'newFile2.png' : file.fileType === 'spreadsheet' ? 'spreadsheet.png' : ''
              "
            />
            <div class="myFiles-item-name">{{ file.name }}</div>
          </div>
        </div>
        <div v-if="!myFiles.length" class="no-files">
          <div class="no-files-text">No files found</div>
        </div>
      </div>
      <div v-else class="no-files">
        <div class="no-files-text">Loading...</div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="text" class="cancel-balsa" @click="dialogVisible = false" style="margin-right:16px;"
          >Cancel</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
// import { io } from 'socket.io-client';
import { ALL_CHAT_ROOMS, MYPROFILE_QUERY } from '../../queries';
import gql from 'graphql-tag';
import ActiveChat from './ActiveChat.vue';
import ChatUsers from './ChatUsers.vue';
import BalsaIcon from '../BalsaIcon.vue';

export default {
  name: 'ChatOverlay',
  data() {
    return {
      activeUser: null,
      activeChatRoom: null,
      messages: [],
      dialogVisible: false,
      selectedFileFromDovl: null,
      filePreview: {
        type: null,
        title: null,
        visible: false,
      },
    };
  },
  methods: {
    // onInit: function() {
    //   this.socket = io(`${process.env.VUE_APP_SOCKETSERVER}/chat-1`, {
    //     path: '/socket.io',
    //     autoConnect: false,
    //     transports: ['websocket', 'polling'],
    //     auth: {
    //       token: `${localStorage.getItem('TOKEN')}`,
    //       userId: `${localStorage.getItem('USERID')}`,
    //       clientID: `${localStorage.getItem('USERID')}.chat`,
    //     },
    //   })
    //     .on('message', data => {
    //       console.log('message', data);
    //     })
    //     .on('connect_error', err => {
    //       console.log('error', err);
    //     });
    // },

    handleSelectToUploadFile(file) {
      this.selectedFileFromDovl = {
        ...file,
        fileType: file.fileType === 'document' ? 'DOCFILE' : file.fileType === 'spreadsheet' ? 'SHEETFILE' : null,
      };

      this.filePreview = {
        title: file.name,
        type: file.fileType === 'document' ? 'doc' : file.fileType === 'spreadsheet' ? 'sheet' : '',
        visible: true,
      };

      return (this.dialogVisible = false);
    },
    setActiveUser(user) {
      this.activeUser = user;
    },
    setMessages(messages) {
      this.messages = messages;
    },
    setActiveChatRoom(chatRoom) {
      this.activeChatRoom = chatRoom;
    },
    toggleMessageListCard() {
      document.querySelector('.card-user-container').classList.toggle('visible');
      document.querySelector('.arrow').classList.toggle('rotate');
    },
    handleDialogVisible(boolean) {
      this.dialogVisible = boolean;
    },
    handleSelectedFileFromDovl(file) {
      this.selectedFileFromDovl = file;
    },
    handleFilePreview(file) {
      this.filePreview = file;
    },
  },
  apollo: {
    allChatRooms: {
      query: ALL_CHAT_ROOMS,
    },
    myProfile: {
      query: MYPROFILE_QUERY,
    },
    myFiles: {
      query: gql`
        query MyFiles {
          myFiles {
            id
            name
            fileType
          }
        }
      `,
    },
    $subscribe: {
      Message: {
        query: gql`
          subscription Message {
            Message {
              id
              createdAt
              message
              type
              file {
                id
                name
              }
              chatRoom {
                id
                users {
                  id
                }
              }
              sender {
                id
                firstName
                lastName
              }
            }
          }
        `,
        result({ data }) {
          if (this.activeUser && this.activeUser.id === data.Message.sender.id) {
            this.messages.push(data.Message);
          }
          if (this.allChatRooms?.chatRooms?.length > 0) {
            this.allChatRooms?.chatRooms.forEach(chatRoom => {
              if (chatRoom.id === data.Message.chatRoom.id) {
                chatRoom.lastMessage = data.Message;
              }
            });
          } else {
            console.log('no chat rooms');
            const filteredOtherChatRooms = this.allChatRooms?.otherChatUsers?.filter(
              user => user.id !== data.Message.sender.id,
            );
            this.allChatRooms.otherChatUsers = filteredOtherChatRooms;

            this.allChatRooms?.chatRooms?.push({
              id: data.Message.chatRoom.id,
              lastMessage: {
                id: data.Message.id,
                createdAt: data.Message.createdAt,
                updatedAt: data.Message.createdAt,
                file: data.Message.file,
                message: data.Message.message,
                sender: data.Message.sender,
              },
              users: [data.Message.sender],
            });

            console.log('this.allChatRooms', this.allChatRooms);
          }
        },
      },
    },
  },
  created() {
    // this.onInit();
  },
  watch: {
    dialogVisible(newValue) {
      if(newValue) {
        this.$apollo.queries.myFiles.refetch();
      }
    }
  },
  components: { ActiveChat, ChatUsers, BalsaIcon },
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

* {
  font-family: Inter, sans-serif;
}

#chat-overlay {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 1999;
  display: flex;
  column-gap: 4px;
  align-items: flex-end;
}

.myFiles-item {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 0 22px;
  border-radius: 8px;
}
.myFiles-item:hover {
  background-color: #f1f2f4;
}
.dark .myFiles-item:hover {
  background-color: rgba(104, 104, 104, 0.3);
}
.myFiles-item > .file-icon {
  color: #33c871;
  width: 22px;
  height: 22px;
}

.myFiles-item-name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 12px;
  color: #363636;
}
.dark .myFiles-item-name {
  color: #ffffff;
}
.no-files {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 42px 16px 16px 16px;
}
.dark .no-files {
  color: rgb(107, 114, 128);
}
</style>

<style>
.myFiles-item .balsa-file-image {
  padding: 16px 0 !important;
}

.myFiles-dropdown .el-dialog__body {
  padding: 12px 16px !important;
  max-height: 45vh !important;
  overflow-y: auto !important;
}
.dark .el-dialog__header {
  border-bottom: 1px solid #535353 !important;
}

.myFiles-dropdown .el-dialog__footer {
  padding: 12px !important;
}
</style>
