<template>
  <div class="main-capsule">
    <slot/>
  </div>
</template>

<script>
export default {};
</script>

<style>
.main-capsule {
  margin-top: 60px;
  flex: 1;
}
</style>
