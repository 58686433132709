<template>
  <div>
    <el-menu
      class="el-menu-demo"
      style="border-right:0"
      placement="bottom"
      mode="vertical"
      active-text-color="#303133"
      @open="true"
    >
      <el-menu-item
        index="add-to-folder"
        v-if="!this.$apollo.queries.File.loading && File.hasWritePermission"
        @click="$store.dispatch('toggleMoveDialog', file)"
        >Add to Folder</el-menu-item
      >
      <el-menu-item
        index="history"
        v-if="!this.$apollo.queries.File.loading && File.fileType === 'document'"
        @click="$store.dispatch('toggleHistoryDialog')"
        >History</el-menu-item
      >
      <Divider class="divider-margin" v-if="!this.$apollo.queries.File.loading && File.hasWritePermission" />
      <el-menu-item
        v-if="File.fileType === 'document'"
        index="export"
        @click="$store.dispatch('toggleExportFileDialog')"
        >Export</el-menu-item
      >
      <el-menu-item v-if="File.fileType === 'spreadsheet'" @click="uploadExcel">Import</el-menu-item>
      <el-menu-item v-if="File.fileType === 'spreadsheet'" @click="exportToExcel">Export</el-menu-item>
      <!-- <el-menu-item style="padding: 0;">
        <label for="upload" class="import-label">
          <span>Import</span>
        </label>
        <input type="file" id="upload" @change="uploadExcel" style="display:none" />
      </el-menu-item>
      <el-menu-item index="export" @click="exportToExcel">Export</el-menu-item> -->
      <el-menu-item
        index="copy-link"
        @click="copyLinkHandler()"
        v-if="!this.$apollo.queries.File.loading && File.hasWritePermission"
        >Copy link</el-menu-item
      >
      <el-menu-item v-if="File.fileType === 'document'" index="print" @click="print">Print</el-menu-item>
      <Divider class="divider-margin" v-if="!this.$apollo.queries.File.loading && File.hasWritePermission" />
      <el-menu-item
        index="share-settings"
        @click="$store.dispatch('toggleFilePermissionDialog')"
        v-if="!this.$apollo.queries.File.loading && File.hasWritePermission"
        >Share</el-menu-item
      >
      <Divider class="divider-margin" v-if="!this.$apollo.queries.File.loading && File.hasWritePermission" />
      <el-menu-item
        index="delete"
        @click="$store.dispatch('toggleRemoveFileDialog')"
        v-if="!this.$apollo.queries.File.loading && File.hasWritePermission"
        >Delete</el-menu-item
      >
    </el-menu>
  </div>
</template>

<script>
import Divider from '../../Divider.vue';
import gql from 'graphql-tag';
import { GET_FILE_PUBLIC_URL } from '../../../queries';
import LuckyExcel from 'luckyexcel';
import { luckyToExcel } from '../../../helpers/luckyToExcel';

export default {
  props: {
    parentProps: {
      type: Object,
    },
  },
  components: {
    Divider,
  },
  apollo: {
    File: {
      query: gql`
        query File($id: Int!, $inviteCode: String) {
          File(id: $id, inviteCode: $inviteCode) {
            id
            name
            content
            hasWritePermission
            fileType
          }
        }
      `,
      variables() {
        return {
          id: parseInt(this.$route.params.id),
          inviteCode: this.$route.params.inviteCode,
        };
      },
      // Error handling
      error(error) {
        console.error("We've got an error!", error.graphQLErrors[0].message);
      },
      result({ data }) {
        this.file = data.File;
      },
    },
    getFilePublicUrl: {
      query: GET_FILE_PUBLIC_URL,
      variables() {
        return {
          id: this.File.id,
        };
      },
      skip() {
        return !this.publicViewClicked;
      },
      result({ data }) {
        const _this = this;
        this.$copyText(data.getFilePublicUrl).then(function(e) {
          _this.$message({
            showClose: true,
            duration: '3000',
            message: `Copied to clipboard`,
            type: 'success',
          });
          _this.publicViewClicked = false;
        });
      },
    },
  },
  methods: {
    print() {
      this.$htmlToPaper('balsa-editor');
    },
    copyLinkHandler(e) {
      this.publicViewClicked = true;
    },
    uploadExcel(evt) {
      document.getElementById('editor-menu-helper-import').click();
    },
    exportToExcel() {
      document.getElementById('editor-menu-helper-export').click();
    },
    // uploadExcel(evt) {
    //   const files = evt.target.files;
    //   if (files == null || files.length == 0) {
    //     alert('No files wait for import');
    //     return;
    //   }
    //   let name = files[0].name;
    //   let suffixArr = name.split('.'),
    //     suffix = suffixArr[suffixArr.length - 1];
    //   if (suffix != 'xlsx') {
    //     alert('Currently only supports the import of xlsx files');
    //     return;
    //   }
    //   LuckyExcel.transformExcelToLucky(files[0], function(exportJson, luckysheetfile) {
    //     if (exportJson.sheets == null || exportJson.sheets.length == 0) {
    //       alert('Failed to read the content of the excel file, currently does not support xls files!');
    //       return;
    //     }
    //     window.luckysheet.destroy();

    //     // window.luckysheet.create({
    //     //   container: 'luckysheet', //luckysheet is the container id
    //     //   showinfobar: false,
    //     //   data: exportJson.sheets,
    //     //   title: exportJson.info.name,
    //     //   userInfo: exportJson.info.name.creator,
    //     // });

    //     window.luckysheet.create({
    //       container: 'luckysheet',
    //       title: exportJson.info.name,
    //       showinfobar: false,
    //       showsheetbar: false,
    //       enableAddRow: false,
    //       enableAddBackTop: false,
    //       data: exportJson.sheets,
    //       hook: {
    //         updated: sheet => {
    //           console.log('sheet updates');
    //           function arrayDifference(arr1, arr2) {
    //             const diff = [];
    //             for (let i = 0; i < arr1.length; i++) {
    //               const row1 = arr1[i];
    //               const row2 = arr2[i];
    //               for (let j = 0; j < row1.length; j++) {
    //                 if (!isObjectEqual(row1[j], row2[j])) {
    //                   // diff.push({ newValue: row1[j], oldValue: row2[j], row: i, col: j });
    //                   diff.push({ newValue: row1[j], row: i, col: j });
    //                 }
    //               }
    //             }
    //             return diff;
    //           }
    //           function isObjectEqual(obj1, obj2) {
    //             if (!obj1 && !obj2) {
    //               return true;
    //             }
    //             if (!obj1 || !obj2) {
    //               return false;
    //             }
    //             const keys1 = Object.keys(obj1);
    //             const keys2 = Object.keys(obj2);
    //             if (keys1.length !== keys2.length) {
    //               return false;
    //             }
    //             for (let key of keys1) {
    //               if (!_.isEqual(obj1[key], obj2[key])) {
    //                 return false;
    //               }
    //             }
    //             return true;
    //           }

    //           const diff = arrayDifference(sheet.curdata, sheet.data);

    //           if (this.socket && !this.ignoreUpdate && diff.length > 0) {
    //             this.socket.emit('cellUpdated', JSON.stringify(diff));
    //           }
    //           this.ignoreUpdate = false;
    //         },
    //       },
    //     });
    //   });
    // },
    // exportToExcel() {
    //   luckyToExcel(luckysheet, 'excel-sheet');
    // },
  },
  data() {
    return {
      file: null,
      publicViewClicked: false,
    };
  },
};
</script>

<style scoped>
.divider-margin {
  margin: 5px 0;
}
.balsa-divider {
  padding: 10px;
}
.el-menu-demo {
  flex-direction: column;
}
.el-menu-item {
  font-size: 15px;

  font-weight: 500;
  height: 33px;
  line-height: 33px;
}
.import-label {
  width: 100%;
  height: 100%;
  display: flex;
  cursor: pointer;
  padding: 0 20px;
}
</style>
