<template>
  <div>
    <CreateFolderHelper />
    <ImportDocumentHelper />
    <CreateFileHelper />
    <TabRouteHelper />
  </div>
</template>

<script>
import CreateFolderHelper from './CreateFolderHelper.vue';
import ImportDocumentHelper from './ImportDocumentHelper.vue';
import CreateFileHelper from './CreateFileHelper.vue';
import TabRouteHelper from './TabRouteHelper.vue';

export default {
  components: { CreateFolderHelper, ImportDocumentHelper, CreateFileHelper, TabRouteHelper },
};
</script>

<style></style>
