<template>
  <div style="position:relative;height:100%">
    <input id="editor-menu-helper-import" style="display: none;" type="file" @change="uploadExcel" />
    <button id="editor-menu-helper-export" style="display: none;" @click="exportToExcel">Export</button>
    <AddToFolderDialog
      :dialogVisible="$store.getters.isMoveDialogOpen"
      class="no-print"
      @handler="$store.dispatch('toggleMoveDialog')"
      :file="Spreadsheet"
      v-if="!this.$apollo.queries.Spreadsheet.loading && $store.getters.isMoveDialogOpen"
    />
    <ExportFileDialog
      :dialogVisible="$store.getters.isExportFileDialogOpen"
      @handler="$store.dispatch('toggleExportFileDialog')"
      @export="exportHandler"
      class="no-print"
      :file="Spreadsheet"
      v-if="!this.$apollo.queries.Spreadsheet.loading && $store.getters.isExportFileDialogOpen"
    />
    <FilePermissionDialog
      :dialogVisible="$store.getters.isFilePermissionDialogOpen"
      @handler="$store.dispatch('toggleFilePermissionDialog')"
      class="no-print"
      :file="Spreadsheet"
      v-if="!this.$apollo.queries.Spreadsheet.loading && $store.getters.isFilePermissionDialogOpen"
    />
    <RemoveFileDialog
      :dialogVisible="$store.getters.isRemoveFileDialogOpen"
      @handler="$store.dispatch('toggleRemoveFileDialog')"
      class="no-print"
      :file="Spreadsheet"
      v-if="!this.$apollo.queries.Spreadsheet.loading && $store.getters.isRemoveFileDialogOpen"
    />
    <div style="position:relative;height:100%;">
      <div
        id="luckysheet"
        style="margin-bottom: 16px;padding:0px;position:absolute;width:100%;left: 0px;top: 0px;bottom:0px;"
      ></div>
    </div>
  </div>
</template>

<script>
import { gql } from 'apollo-server-core';
import { io } from 'socket.io-client';
import LuckyExcel from 'luckyexcel';
import { luckyToExcel } from '../helpers/luckyToExcel';
import _ from 'lodash';

import Icon from './Icon';
import AddToFolderDialog from './Dialogs/AddToFolderDialog';
import ExportFileDialog from './Dialogs/ExportFileDialog';
import FilePermissionDialog from './Dialogs/FilePermissionDialog';
import RemoveFileDialog from './Dialogs/RemoveFileDialog';
import Avatar from './Avatar.vue';

export default {
  name: 'LuckySheet',
  components: {
    Avatar,
    AddToFolderDialog,
    ExportFileDialog,
    FilePermissionDialog,
    Icon,
    RemoveFileDialog,
  },
  data() {
    return {
      socket: null,
      ignoreUpdate: false,
    };
  },
  props: {
    myProfile: {
      type: Object,
    },
    handleUpdateSocketContributors: {
      type: Function,
    },
  },
  methods: {
    onInit: function(spreadsheet) {
      this.socket = io(`${process.env.VUE_APP_SOCKETSERVER}/sheet-${spreadsheet.id}`, {
        path: '/socket.io',
        autoConnect: false,
        transports: ['websocket', 'polling'],
        auth: {
          token: `${localStorage.getItem('TOKEN')}`,
          userId: `${localStorage.getItem('USERID')}`,
          clientID: `${localStorage.getItem('USERID')}.sheet-${spreadsheet.id}`,
        },
      })
        .on('rangeUpdated', data => {
          if (this.ignoreUpdate) return;
          this.ignoreUpdate = true;
          const parsedData = JSON.parse(data);
          luckysheet.setRangeValue(parsedData.data, {
            range: { row: parsedData.rowRange, column: parsedData.columnRange },
          });
        })
        .on('sheetImported', data => {
          const parsedData = JSON.parse(data);
          luckysheet.destroy();
          luckysheet.create({
            container: 'luckysheet',
            title: 'Luckysheet Demo',
            showinfobar: false,
            showsheetbar: false,
            enableAddRow: false,
            enableAddBackTop: false,
            data: [
              {
                name: 'Sheet 1',
                data: parsedData,
              },
            ],
            hook: {
              updated: sheet => {
                function getRangeData(data, rowRange, columnRange) {
                  const startRow = rowRange[0];
                  const endRow = rowRange[1];
                  const startColumn = columnRange[0];
                  const endColumn = columnRange[1];

                  const result = [];

                  for (let i = startRow; i <= endRow; i++) {
                    const row = data[i];
                    const rowData = [];
                    for (let j = startColumn; j <= endColumn; j++) {
                      const cell = row[j];
                      if (cell && !cell?.v) cell.v = null;
                      if (cell && !cell?.m) cell.m = null;
                      rowData.push(cell);
                    }
                    result.push(rowData);
                  }

                  return result;
                }

                const rowRange = sheet.range[0].row;
                const columnRange = sheet.range[0].column;
                const diff = getRangeData(sheet.curdata, rowRange, columnRange);

                const data = {
                  rowRange,
                  columnRange,
                  data: diff,
                };

                if (this.socket && diff.length > 0 && this.ignoreUpdate === false) {
                  this.socket.emit('rangeUpdated', JSON.stringify(data));
                  setTimeout(() => {
                    this.sendPreviewToSocket(socketRef);
                  }, 50);
                }
                this.ignoreUpdate = false;
              },
            },
          });
        })
        .on('connect_error', err => {
          console.log('error', err);
        });

      if (this.$route.name !== 'readOnlyEditor') {
        this.socket.connect();
      }
    },
    uploadExcel(evt) {
      const files = evt.target.files;
      if (files == null || files.length == 0) {
        alert('No files wait for import');
        return;
      }
      let name = files[0].name;
      let suffixArr = name.split('.'),
        suffix = suffixArr[suffixArr.length - 1];
      if (suffix != 'xlsx') {
        alert('Currently only supports the import of xlsx files');
        return;
      }
      const socketRef = this.socket;
      const sendPreviewToSocketRef = this.sendPreviewToSocket;
      this.ignoreUpdate = false;
      let ignoreUpdate = false;

      LuckyExcel.transformExcelToLucky(files[0], function(exportJson, luckysheetfile) {
        if (exportJson.sheets == null || exportJson.sheets.length == 0) {
          alert('Failed to read the content of the excel file, currently does not support xls files!');
          return;
        }
        luckysheet.destroy();

        luckysheet.create({
          container: 'luckysheet',
          title: exportJson.info.name,
          showinfobar: false,
          showsheetbar: false,
          enableAddRow: false,
          enableAddBackTop: false,
          data: exportJson.sheets,
          hook: {
            updated: sheet => {
              function getRangeData(data, rowRange, columnRange) {
                const startRow = rowRange[0]; // Diziler sıfır tabanlı olduğundan, dizinleri 1 azaltmalıyız.
                const endRow = rowRange[1];
                const startColumn = columnRange[0];
                const endColumn = columnRange[1];

                const result = [];

                for (let i = startRow; i <= endRow; i++) {
                  const row = data[i];
                  const rowData = [];
                  for (let j = startColumn; j <= endColumn; j++) {
                    const cell = row[j];
                    if (cell && !cell?.v) cell.v = null;
                    if (cell && !cell?.m) cell.m = null;
                    rowData.push(cell);
                  }
                  result.push(rowData);
                }

                return result;
              }

              const rowRange = sheet.range[0].row;
              const columnRange = sheet.range[0].column;
              const diff = getRangeData(sheet.curdata, rowRange, columnRange);

              const data = {
                rowRange,
                columnRange,
                data: diff,
              };

              if (socketRef && diff.length > 0 && ignoreUpdate === false) {
                socketRef.emit('rangeUpdated', JSON.stringify(data));
                setTimeout(() => {
                  sendPreviewToSocketRef(socketRef);
                }, 50);
              }
              ignoreUpdate = false;
            },
          },
        });

        const importedSheet = JSON.stringify(exportJson.sheets[0].data);
        if (socketRef) {
          socketRef.emit('sheetImported', importedSheet);
          setTimeout(() => {
            sendPreviewToSocketRef(socketRef);
          }, 50);
        }
      });

      evt.target.value = '';
    },
    exportToExcel() {
      luckyToExcel(luckysheet, 'excel-sheet');
    },
    sendPreviewToSocket(socket) {
      try {
        const canvas = document.querySelector('#luckysheetTableContent');
        const resizedCanvas = document.createElement('canvas');
        const resizedContext = resizedCanvas.getContext('2d');

        resizedCanvas.width = 488;
        resizedCanvas.height = 338;

        resizedContext.drawImage(canvas, 0, 0);

        const base64 = resizedCanvas.toDataURL();
        socket.emit('canvasUpdated', base64);
      } catch {
        window.location.reload();
      }
    },
  },
  apollo: {
    Spreadsheet: {
      query: gql`
        query Spreadsheet($id: Int!) {
          Spreadsheet(id: $id) {
            id
            name
            content
            isStarred
            hasWritePermission
            user {
              id
              firstName
              lastName
            }
            contributors {
              user {
                id
                firstName
                lastName
              }
            }
          }
        }
      `,
      variables() {
        return {
          id: parseInt(this.$route.params.id),
          log: true,
        };
      },
      // Error handling
      error(error) {
        console.error("We've got an error!", error.graphQLErrors[0].message);
      },
      result({ data }) {
        this.onInit(data.Spreadsheet);
        this.handleUpdateSocketContributors(data.Spreadsheet.contributors);
        luckysheet.create({
          container: 'luckysheet',
          title: 'Luckysheet Demo',
          showinfobar: false,
          showsheetbar: false,
          enableAddRow: false,
          enableAddBackTop: false,
          data: [
            {
              name: 'Sheet 1',
              data: JSON.parse(data.Spreadsheet.content),
            },
          ],
          hook: {
            updated: sheet => {
              function getRangeData(data, rowRange, columnRange) {
                const startRow = rowRange[0]; // Diziler sıfır tabanlı olduğundan, dizinleri 1 azaltmalıyız.
                const endRow = rowRange[1];
                const startColumn = columnRange[0];
                const endColumn = columnRange[1];

                const result = [];

                for (let i = startRow; i <= endRow; i++) {
                  const row = data[i];
                  const rowData = [];
                  for (let j = startColumn; j <= endColumn; j++) {
                    const cell = row[j];
                    if (cell && !cell?.v) cell.v = null;
                    if (cell && !cell?.m) cell.m = null;
                    rowData.push(cell);
                  }
                  result.push(rowData);
                }

                return result;
              }

              const rowRange = sheet.range[0].row;
              const columnRange = sheet.range[0].column;
              const diff = getRangeData(sheet.curdata, rowRange, columnRange);

              const data = {
                rowRange,
                columnRange,
                data: diff,
              };

              if (this.socket && diff.length > 0 && this.ignoreUpdate === false) {
                this.socket.emit('rangeUpdated', JSON.stringify(data));
                setTimeout(() => {
                  this.sendPreviewToSocket(this.socket);
                }, 50);
              }
              this.ignoreUpdate = false;
            },
          },
        });
        setTimeout(() => {
          this.sendPreviewToSocket(this.socket);
        }, 50);
      },
    },
    contributor: {
      query: gql`
        query contributor($inviteCode: String) {
          contributor(inviteCode: $inviteCode) {
            id
            permissionLevel
            email
            isAnon
            user {
              id
              email
            }
          }
        }
      `,
      skip() {
        return !this.$route.params.inviteCode;
      },
      variables() {
        return {
          inviteCode: this.$route.params.inviteCode,
        };
      },
      result({ data }) {
        if (data.contributor.permissionLevel !== 'READ_WRITE') {
          this.editor.setOptions({
            editable: false,
          });
        }
      },
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
