import { render, staticRenderFns } from "./PopoverImage.vue?vue&type=template&id=79e77339&scoped=true&"
import script from "./PopoverImage.vue?vue&type=script&lang=js&"
export * from "./PopoverImage.vue?vue&type=script&lang=js&"
import style0 from "./PopoverImage.vue?vue&type=style&index=0&id=79e77339&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "79e77339",
  null
  
)

export default component.exports