<template>
  <div class="card">
    <div class="card-header" @click="toggleMessageListCard">
      <div class="card-header-title">Chat with Team</div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth="{1.5}"
        stroke="currentColor"
        class="arrow"
      >
        <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
      </svg>
    </div>
    <div class="card-user-container visible">
      <div
        class="card-user"
        v-if="allChatRooms.chatRooms"
        v-for="chatRoom in allChatRooms.chatRooms"
        :key="chatRoom.id"
        :class="activeUser && activeUser.id === chatRoom.users.filter(user => user.id !== myProfile.id)[0].id && 'active'"
        @click="handleActiveUser(chatRoom.users.filter(user => user.id !== myProfile.id)[0])"
      >
        <Avatar
          size="smallAvatar"
          :firstName="chatRoom.users.filter(user => user.id !== myProfile.id)[0].firstName"
          :lastName="chatRoom.users.filter(user => user.id !== myProfile.id)[0].lastName"
          :src="chatRoom.users.filter(user => user.id !== myProfile.id)[0].profilePhoto"
        />
        <div class="card-user-details">
          <div class="card-user-name">{{ chatRoom.users.filter(user => user.id !== myProfile.id)[0].firstName + ' ' + chatRoom.users.filter(user => user.id !== myProfile.id)[0].lastName }}</div>
          <div class="card-user-message">
            {{
              chatRoom.lastMessage &&
                convertLastMessage(chatRoom.lastMessage.sender, chatRoom.lastMessage.message, chatRoom.lastMessage.file)
            }}
          </div>
        </div>
        <div class="card-user-time">{{ chatRoom.lastMessage && convertDate(chatRoom.lastMessage.createdAt) }}</div>
      </div>
      <div
        class="card-user"
        v-if="allChatRooms.otherChatUsers"
        v-for="user in allChatRooms.otherChatUsers"
        :key="user.id"
        :class="activeUser && activeUser.id === user.id && 'active'"
        @click="handleActiveUser(user)"
      >
        <Avatar size="smallAvatar" :firstName="user.firstName" :lastName="user.lastName" :src="user.profilePhoto" />
        <div class="card-user-details">
          <div class="card-user-name">{{ user.firstName + ' ' + user.lastName }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import gql from 'graphql-tag';
import Avatar from '../Avatar.vue';
import moment from 'moment';

export default {
  name: 'ChatUsers',
  props: {
    toggleMessageListCard: {
      type: Function,
    },
    allChatRooms: {
      type: Object,
    },
    activeUser: {
      type: Object,
    },
    myProfile: {
      type: Object,
    },
    setActiveUser: {
      type: Function,
    },
    setMessages: {
      type: Function,
    },
    setActiveChatRoom: {
      type: Function,
    },
  },
  components: { Avatar },
  methods: {
    handleActiveUser(user) {
      this.setActiveUser(user);
      this.$apollo
        .query({
          query: gql`
            query($targetUserId: ID!) {
              getChatRoom(targetUserId: $targetUserId) {
                id
                createdAt
                updatedAt
                messages {
                  id
                  createdAt
                  message
                  type
                  file {
                    id
                    name
                  }
                  sender {
                    id
                    firstName
                    lastName
                  }
                }
              }
            }
          `,
          variables: {
            targetUserId: user.id,
          },
        })
        .then(res => {
          const chatRoom = res.data.getChatRoom;
          if (!chatRoom) {
            this.setActiveChatRoom(null);
            return this.setMessages([]);
          }
          this.setActiveChatRoom(chatRoom);
          return this.setMessages(chatRoom.messages);
        });
    },
    convertLastMessage(sender, message, file) {
      const prefix = this.myProfile.id === sender.id ? 'Me: ' : sender.firstName + ' ' + sender.lastName + ': ';

      if (file) {
        return prefix + 'Sent a file';
      }

      return prefix + message;
    },
    convertDate(createdAt) {
      return moment(parseInt(createdAt)).fromNow();
    },
  },
};
</script>

<style scoped>
* {
  font-family: Inter, sans-serif;
}

.arrow {
  cursor: pointer;
  color: #000000;
  width: 24px;
  height: 24px;
  border-radius: 50%;
}

.rotate {
  transform: rotate(180deg);
}

.dark .arrow {
  color: #ffffff;
}
.card {
  background: white;
  min-width: 360px;
  border: 1px solid #f1f2f4;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
}
.dark .card {
  background: #1a1a1a;
  border: 1px solid #2a2a2a;
}
.card-header {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgb(242, 244, 247);
  padding: 12px;
}
.dark .card-header {
  border-bottom: 1px solid #2a2a2a;
}
.card-header-title {
  font-size: 16px;
  font-weight: 500;
  color: rgb(17, 25, 39);
}

.dark .card-header-title {
  color: #ffffff;
}
.card-user-container {
  display: flex;
  flex-direction: column;
  max-height: 50vh;
  overflow: auto;
  padding: 16px 8px;
}

.visible {
  padding: 0;
  height: 0;
  transition: height 0.3s ease-in-out;
}

.card-user-container::-webkit-scrollbar {
  width: 6px;
}

.card-user-container::-webkit-scrollbar-thumb {
  background-color: #a7a7a7;
  border-radius: 6px;
}

.active {
  background-color: rgba(17, 25, 39, 0.04);
}
.dark .active {
  background-color: rgba(163, 163, 163, 0.085);
}
.card-user {
  cursor: pointer;
  display: flex;
  align-items: center;
  border-radius: 20px;
  cursor: pointer;
  padding: 16px 24px;
  margin-bottom: 4px;
}
.card-user:last-child {
  margin-bottom: 0;
}

.card-user:hover {
  background-color: #f5f5f5;
}

.dark .card-user:hover {
  background-color: #2a2a2a;
}
.card-user-details {
  display: flex;
  flex-direction: column;
  margin-left: 12px;
}

.card-user-name {
  font-size: 0.75rem;
  font-weight: 500;
  color: rgb(17, 25, 39);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.dark .card-user-name {
  color: #ffffff;
}

.card-user-message {
  font-size: 0.75rem;
  font-weight: 500;
  color: rgb(108, 115, 127);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.dark .card-user-message {
  color: #ffffff8e;
}

.card-user-time {
  margin-left: auto;
  font-size: 0.65rem;
  font-weight: 500;
  color: rgb(108, 115, 127);
  white-space: nowrap;
}

.dark .card-user-time {
  color: #ffffffdc;
}

.card-user-message {
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
