<template>
  <div class="file-attachment-preview" :class="{ blue: type === 'doc', green: type === 'sheet' }">
    <img
      v-if="type === 'doc' || type === 'sheet'"
      :src="type === 'doc' ? getImgUrl('newFile2.png') : type === 'sheet' ? getImgUrl('spreadsheet.png') : ''"
      alt="balsa"
      class="file-attachment-preview-icon"
    />
    <div class="file-attachment-preview-title">{{ content || type ? title : 'Uploading...' }}</div>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="currentColor"
      class="file-attachment-icon"
      @click="removeFile"
    >
      <path
        fill-rule="evenodd"
        d="M12 10.586l4.95-4.95a.75.75 0 011.06 1.06l-4.95 4.95 4.95 4.95a.75.75 0 11-1.06 1.06l-4.95-4.95-4.95 4.95a.75.75 0 01-1.06-1.06l4.95-4.95-4.95-4.95a.75.75 0 111.06-1.06L12 10.586z"
        clip-rule="evenodd"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: 'FileAttachmentPreview',
  methods: {
    getImgUrl(icon) {
      return require('../../assets/' + icon);
    },
  },
  props: {
    type: {
      type: String,
    },
    title: {
      type: String,
    },
    removeFile: {
      type: Function,
    },
    content: {
      type: Object,
    },
  },
};
</script>

<style scoped>
.file-attachment-preview {
  display: flex;
  align-items: center;
  padding: 0 12px;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  color: #ffffff;
  background-color: #a3a3a3;
  font-weight: 400;
}
.green {
  background-color: #33c871;
}

.blue {
  background-color: rgb(0 122 255);
}

.file-attachment-preview > .file-attachment-title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 12px;
  color: #ffffff;
}

.file-attachment-preview-icon {
  width: 20px;
  height: 20px;
  margin-right: 8px;
  margin-top: 4px;
  margin-bottom: 4px;
}
.file-attachment-preview > .file-attachment-icon {
  cursor: pointer;
  color: #ffffff;
  width: 22px;
  height: 22px;
  margin: 4px 0 4px auto;
}
</style>
