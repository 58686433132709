var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Dialog',{attrs:{"visible":_vm.dialogVisible,"dialogActionChange":_vm.dialogActionChange,"dialogSize":"el-dialog-balsa big-dialog"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('el-row',[_c('el-col',{attrs:{"md":24}},[_c('span',{staticClass:"font-heavy font-size-28"},[_vm._v("Invite Users")])]),_c('el-col',{attrs:{"md":24}},[_c('span',{staticClass:"medium-font pale"},[_vm._v("Fill information below to invite user(s).")])])],1)]},proxy:true},{key:"main",fn:function(){return [_c('el-row',{staticClass:"slot-container-main",staticStyle:{"padding":"0px 25px"}},[_c('el-form',{ref:"form",attrs:{"label-position":"top","label-width":"100px","model":_vm.form}},_vm._l((_vm.form.inviteData),function(data,index){return _c('el-row',{key:index,attrs:{"gutter":16,"type":"flex"}},[_c('el-col',{attrs:{"md":24}},[_c('el-form-item',{attrs:{"prop":'inviteData.' + index + '.email',"rules":[
                { required: true, message: 'Please input email address', trigger: 'blur' },
                { type: 'email', message: 'Please enter a valid email.', trigger: ['blur', 'change'] }
              ]}},[_c('el-input',{attrs:{"placeholder":"E-mail"},model:{value:(data.email),callback:function ($$v) {_vm.$set(data, "email", $$v)},expression:"data.email"}})],1)],1),_c('el-col',{attrs:{"md":24}},[_c('el-form-item',{attrs:{"prop":'inviteData.' + index + '.firstName',"rules":{
                required: true, message: 'Please enter your first name.', trigger: 'blur'
              }}},[_c('el-input',{attrs:{"placeholder":"First Name"},model:{value:(data.firstName),callback:function ($$v) {_vm.$set(data, "firstName", $$v)},expression:"data.firstName"}})],1)],1),_c('el-col',{attrs:{"md":24}},[_c('el-form-item',{attrs:{"prop":'inviteData.' + index + '.lastName',"rules":{
                required: true, message: 'Please enter your last name.', trigger: 'blur'
              }}},[_c('el-input',{attrs:{"placeholder":"Last Name"},model:{value:(data.lastName),callback:function ($$v) {_vm.$set(data, "lastName", $$v)},expression:"data.lastName"}})],1)],1),_c('el-col',{attrs:{"md":24}},[_c('el-form-item',[_c('el-select',{staticClass:"balsa-select",staticStyle:{"width":"100%"},attrs:{"placeholder":"Please select a role"},model:{value:(data.role),callback:function ($$v) {_vm.$set(data, "role", $$v)},expression:"data.role"}},[_c('el-option',{key:0,attrs:{"label":"User","value":"User"}}),_c('el-option',{key:0,attrs:{"label":"Admin","value":"Admin"}})],1)],1)],1),_c('el-col',{attrs:{"md":3}},[(index !==0)?_c('el-form-item',{staticClass:"cursor-pointer"},[_c('div',{on:{"click":function($event){return _vm.deleteSelected(index)}}},[_c('i',{staticClass:"el-icon-close"})])]):_vm._e()],1)],1)}),1)],1),_c('el-row',{staticStyle:{"padding":"0px 25px"},attrs:{"type":"flex"}},[_c('el-button',{staticClass:"semi-medium-font",attrs:{"type":"text","size":"mini","plain":false,"icon":"el-icon-plus"},on:{"click":_vm.addMore}},[_vm._v("Add more")])],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }