<template>
  <div class="balsa-container">
    <div class="balsa-template">
      <el-dialog
        title="Select Your Template"
        :visible.sync="dialogFormVisible"
        custom-class="el-dialog-balsa bigger-dialog template-dialog template-dialog-margin"
      >
        <Templates @handler="selectedCard" />
        <span slot="footer" class="dialog-footer">
          <el-button type="text" @click="dialogFormVisible = false" style="color:#9ca2aa;margin-right:20px;">
            <span class="small-span">Cancel</span>
          </el-button>

          <el-button type="primary" @click="createSelectedTemplate" :disabled="selectedTemplateCard ? false : true"
            >Create File</el-button
          >
        </span>
      </el-dialog>
    </div>
    <span id="magny-helper-create-document" @click="createFile(true)"></span>
    <span id="magny-helper-create-spreadsheet" @click="createSpreadsheet(true)"></span>
    <span id="magny-helper-create-from-template" @click="dialogFormVisible = true"></span>
  </div>
</template>

<script>
import gql from 'graphql-tag';
import Templates from '../Templates/Templates.vue';
import _ from 'lodash';

export default {
  methods: {
    createSelectedTemplate() {
      this.dialogFormVisible = false;
      this.createFile();
    },
    selectedCard(card) {
      //Selected card coming from Temlplates.vue by using EMİT.
      this.selectedTemplateCard = card;
    },
    checkRoot(to) {
      if (to.path === '/' || to.path === '/home') {
        this.quickAccess = false;
      } else {
        this.quickAccess = true;
      }
    },
    createFile(inFolder) {
      this.$apollo
        .mutate({
          mutation: gql`
            mutation createFile($content: String, $folderId: Int, $templateId: Int) {
              createFile(content: $content, folderId: $folderId, templateId: $templateId) {
                id
                name
                content
                user {
                  id
                  firstName
                  lastName
                }
                updatedAt
              }
            }
          `,
          variables: {
            folderId: inFolder ? parseInt(this.$route.params.id) : undefined,
            templateId: _.get(this, 'selectedTemplateCard.id', false) ? this.selectedTemplateCard.id : undefined,
          },
        })
        .then(({ data }) => {
          this.$router.push({ name: 'editor', params: { id: data.createFile.id } });
        });
    },
    createSpreadsheet(inFolder) {
      this.$apollo
        .mutate({
          mutation: gql`
            mutation createSpreadsheet($content: String, $folderId: Int, $templateId: Int) {
              createSpreadsheet(content: $content, folderId: $folderId, templateId: $templateId) {
                id
                name
                content
                user {
                  id
                  firstName
                  lastName
                }
                updatedAt
              }
            }
          `,
          variables: {
            folderId: inFolder ? parseInt(this.$route.params.id) : undefined,
            templateId: _.get(this, 'selectedTemplateCard.id', false) ? this.selectedTemplateCard.id : undefined,
          },
        })
        .then(({ data }) => {
          this.$router.push({ name: 'spreadsheet', params: { id: data.createSpreadsheet.id } });
        });
    },
  },
  data: function() {
    return {
      selectedTemplateCard: null,
      dialogFormVisible: false,
      visible: false,
    };
  },
  components: {
    Templates,
  },
  created() {
    this.checkRoot(this.$route);
  },
  watch: {
    $route(to, from) {
      this.checkRoot(to);
    },
  },
};
</script>

<style lang="scss">
.template-dialog .el-dialog__body {
  padding: 0;
}
.template-dialog .el-dialog__header {
  padding: 32px 24px;
}
.template-dialog .el-dialog__footer {
  border: solid 1px #e6e6e6;
}
.template-dialog-margin {
  margin: 0 !important;
}
.balsa-template {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
