var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-row',{staticStyle:{"flex-direction":"row-reverse"},attrs:{"type":"flex","align":"middle"}},[(_vm.myAvatar)?_c('div',[_c('div',{style:({
        left: _vm.startIndex * _vm.index + 'px',
        position: 'relative',
        display: 'flex',
      })},[_c('el-tooltip',{attrs:{"effect":"dark","content":_vm.myAvatar.firstName,"placement":"top-start","visible-arrow":false}},[_c('Avatar',{attrs:{"av":_vm.myAvatar,"src":_vm.myAvatar.profilePhoto,"size":_vm.size,"firstName":_vm.myAvatar.firstName,"lastName":_vm.myAvatar.lastName}})],1)],1)]):_vm._e(),_vm._l((_vm.avatars.slice(0, _vm.max)),function(avatar,index){return (_vm.avatars.length)?_c('div',{key:index + 1},[_c('div',{style:({
        left: _vm.startIndex * index + 'px',
        position: 'relative',
        'z-index': _vm.avatars.length - index,
        display: 'flex',
      })},[_c('el-tooltip',{attrs:{"effect":"dark","content":avatar.firstName,"placement":"top-start","visible-arrow":false}},[_c('Avatar',{attrs:{"av":avatar,"src":avatar.profilePhoto,"size":_vm.size,"firstName":avatar.firstName,"lastName":avatar.lastName}})],1)],1)]):_vm._e()}),(_vm.avatars.length > _vm.max)?_c('div',{staticStyle:{"display":"flex","align-items":"center"},style:({ left: -10 * _vm.avatars.slice(0, _vm.max).length + 10 + 'px', position: 'relative', 'z-index': 0 })},[_c('span',{staticClass:"is-more"},[_vm._v("+"+_vm._s(_vm.avatars.length - _vm.max))])]):_vm._e(),_c('div',{on:{"click":function($event){return _vm.$store.dispatch('toggleFilePermissionDialog')}}},[(_vm.addAvatar)?_c('addAvatar',{style:({ 'margin-left': '8px', position: 'relative', 'z-index': 0 })}):_vm._e()],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }