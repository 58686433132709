<template>
  <el-row style="padding-left:15px;">
    <div id="history-card-container" v-if="historyArray.length > 0">
      <el-col v-for="(history, index) in historyArray" class="history-col" :key="index">
        <div @click="handleView(index)">
          <el-row v-bind:class="(index === selectedIndex) ? 'history-card selected-card' : 'history-card'">
            <div class="width-info">
              <h3>
                By {{ history.user.firstName + ' ' + history.user.lastName }}
              </h3>
              <p class="history-date">
                Created at {{ date(history.createdAt) }}
              </p>
            </div>
            <div class="width-button" v-if="!history.isCurrentVersion">
              <el-button @click="createFile(index)" type="text" class="semi-medium-font set-current-button">
                <span>
                  Set Current
                </span>
              </el-button>
            </div>
          </el-row>
        </div>
      </el-col>
    </div>
    <div v-else>
      <h4 style="text-align: center; margin-top:48%;">There is no history.</h4>
    </div>
  </el-row>
</template>
  
<script>
import Avatar from '../Avatar.vue';
import Dialog from '../Dialog.vue';
import moment from 'moment';
import FilePermissionEditMenu from '../Menu/FilePermissionEditMenu/FilePermissionEditMenu.vue';
import Divider from '../Divider.vue';
import gql from 'graphql-tag';
import NotificationMixin from '../Mixins/NotificationMixin';
import { MYPROFILE_QUERY } from '../../queries';

export default {
  mixins: [NotificationMixin],
  name: 'FilePermissionDialog',
  components: {
    Avatar,
    Dialog,
    FilePermissionEditMenu,
    Divider,
  },
  apollo: {
    myProfile: {
      query: MYPROFILE_QUERY,
      skip() {
        return this.$route.name === 'readOnlyEditor';
      },
      result({ data }) {
        this.myProfile = data.myProfile;
        return data.myProfile;
      }
    },
    getAllHistory: {
      query: gql`
          query getAllHistory($fileId: Int!) {
            getAllHistory(fileId: $fileId) {
              historyJSONContent
              contentMarkedHtml
              createdAt
              user {
                firstName
                lastName
                id
              }
            }
          }
        `,
      variables() {
        return {
          fileId: this.file.id
        }
      },
      result({ data }) {
        this.historyArray = data.getAllHistory
        this.handleView(0)
      }
    },
    File: {
      query: gql`
          query File($id: Int!) {
            File(id: $id) {
              user{
                id
                firstName
                lastName
              }
              id
              contributors {
                id
                isAnon
                email
                user {
                  id
                  profilePhoto
                  firstName
                  lastName
                  email
                  jobTitle
                }
              }
            }
          }
        `,
      variables() {
        return { id: this.file.id };
      },
      skip() {
        return !this.dialogVisible;
      },
      result({ data }) {
        this.File = data.File;
        this.parentFileId = data.File.id;
        this.accessUser = data.File.contributors;
        this.fileOwner = { id: data.File.id }
        return data.File;
      }
    },
  },
  data: function () {
    return {
      isHistoryMode: true,
      historyArray: [],
      parentFileId: -1,
      selectedIndex: 0,
      isHandleView: true
    };
  },
  props: {
    editor: {
      type: Object
    },
    file: {
      type: Object,
    },
    dialogVisible: {
      type: Boolean,
    },
    func: {
      type: Function,
    }
  },
  methods: {
    
    date(a) {
      return moment(parseInt(a)).local().format('YYYY-MM-DD HH:mm:ss');
    },
    handleView(value) {
      this.func(this.historyArray[value].contentMarkedHtml)
      if (this.isHandleView) {
        this.selectedIndex = value
      } else {
        this.isHandleView = true
      }
    },
    createFile(index) {
      document.getElementById("history-card-container").scrollTo(0, 0)
      let json = JSON.parse(this.historyArray[index].historyJSONContent)
      this.selectedIndex = 0
      this.isHandleView = false
      this.editor.setContent(json)
      this.$apollo.mutate({
        mutation: gql`mutation ($fileId: Int!,$historyJSONContent: String!, $contentMarkedHtml: String!) {
            createHistory(fileId: $fileId,historyJSONContent: $historyJSONContent, contentMarkedHtml: $contentMarkedHtml) {
              id
              historyJSONContent
            }
          }`,
        variables: {
          fileId: this.parentFileId,
          historyJSONContent: JSON.stringify(this.editor.getJSON()),
          contentMarkedHtml: this.editor.getHTML()
        },
        refetchQueries: ['getAllHistory']
      })
    },
    dialogActionChange(event) {
      if (event === 'confirm') {
        if (this.form.select.length) {
          this.givePermission();
        }
      } else {
        // close geliyor ancak handle etmeye gerek yok
      }
      this.$emit('handler');
    },
  },
};
</script>
  
<style scoped>
.width-100 {
  width: 100%
}

.width-button {
  width: 34%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.width-info {
  width: 66%;
}

.history-card {
  border: 1px solid grey;
  padding: 12px;
  display: flex;
  border-radius: 4px;
  margin-bottom: 8px;
  cursor: pointer;
}

.history-card:hover {
  opacity: 0.7;
}

.color-white {
  background-color: grey !important;
}

.history-date {
  color: #007aff !important;
}

.selected-card {
  border: 3px #007aff solid;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: inherit;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

#history-card-container {
  overflow-y: scroll;
  height: 75vh;
  padding-right: 2px;
}

.font-size-28 {
  font-size: 22px;
}
</style>
  
