<template>
  <el-container>
    <EditorHeader :myProfile="myProfile" :socketContributorIds="socketContributorIds" />
    <Main class="sub-main balsa-header-bg">
      <Editor ref="editor" :myProfile="myProfile" @updateSocketContributors="handleUpdateSocketContributors" />
    </Main>
  </el-container>
</template>

<script>
import EditorHeader from './Headers/EditorHeader.vue';
import Editor from './EditorCollab.vue';
import Main from './Main.vue';
import { MYPROFILE_QUERY } from '../queries';
export default {
  name: 'EditorRouter',
  components: { EditorHeader, Main, Editor },
  data() {
    return {
      socketContributorIds: [],
    };
  },
  methods: {
    handleUpdateSocketContributors(participantsJSON) {
      this.socketContributorIds = Object.keys(participantsJSON).map(str => parseInt(str));
    },
  },
  props: {
    msg: String,
  },
  apollo: {
    myProfile: {
      query: MYPROFILE_QUERY,
      skip() {
        return this.$route.name === 'readOnlyEditor';
      },
    },
  },
};
</script>
