const state = {
  user: {},
  isSaving: false,
  isDemoMode: false,
  isSecure: process.env.VUE_APP_IS_SECURE === 'true',
  isMoveDialogOpen: false,
  isExportFileDialogOpen: false,
  isFilePermissionDialogOpen: false,
  isHistoryDialogOpen: false,
  isRemoveFileDialogOpen: false,
  isRenameFileDialogOpen: false,
  isHistoryMode: false,

  file: { id: null },
};

const getters = {
  userInfo(state) {
    return state.user;
  },
  isDemoMode(state) {
    return state.isDemoMode;
  },
  isSecure(state) {
    return state.isSecure;
  },
  isSaving(state) {
    return state.isSaving;
  },
  isMoveDialogOpen(state) {
    return state.isMoveDialogOpen;
  },
  isExportFileDialogOpen(state, data) {
    return state.isExportFileDialogOpen;
  },
  isFilePermissionDialogOpen(state, data) {
    return state.isFilePermissionDialogOpen && !state.isDemoMode;
  },
  isHistoryDialogOpen(state, data) {
    return state.isHistoryDialogOpen;
  },
  isRemoveFileDialogOpen(state, data) {
    return state.isRemoveFileDialogOpen;
  },
  isRenameFileDialogOpen(state, data) {
    return state.isRenameFileDialogOpen;
  },
  isHistoryMode(state) {
    return state.isHistoryMode;
  },
  getFile(state) {
    return state.file;
  },
};

const mutations = {
  setUserInformation(state, data) {
    state.user = data;
  },
  setSavingState(state, data) {
    state.isSaving = data;
  },
  toggleMoveDialog(state, data) {
    if (data) {
      state.file = data;
    }
    state.isMoveDialogOpen = !state.isMoveDialogOpen;
  },
  toggleExportFileDialog(state, data) {
    state.isExportFileDialogOpen = !state.isExportFileDialogOpen;
  },
  toggleFilePermissionDialog(state, data) {
    state.isFilePermissionDialogOpen = !state.isFilePermissionDialogOpen;
  },
  toggleHistoryMode(state, data) {
    state.isHistoryMode = data === undefined ? !state.isHistoryMode : false;
  },
  toggleHistoryDialog(state, data) {
    state.isHistoryDialogOpen = data === undefined ? !state.isHistoryDialogOpen : false;
  },
  toggleRemoveFileDialog(state, data) {
    state.isRemoveFileDialogOpen = !state.isRemoveFileDialogOpen;
  },
};

const actions = {
  saveUserInformation(context, data) {
    context.commit('setUserInformation', data);
  },
  updateSavingState(context, data) {
    context.commit('setSavingState', data);
  },
  toggleMoveDialog(context, data) {
    context.commit('toggleMoveDialog', data);
  },
  toggleExportFileDialog(context, data) {
    context.commit('toggleExportFileDialog', data);
  },
  toggleFilePermissionDialog(context, data) {
    context.commit('toggleFilePermissionDialog', data);
  },
  toggleHistoryMode(context, data) {
    context.commit('toggleHistoryMode', data);
  },
  toggleHistoryDialog(context, data) {
    context.commit('toggleHistoryDialog', data);
  },
  toggleRemoveFileDialog(context, data) {
    context.commit('toggleRemoveFileDialog', data);
  },
  toggleRenameFileDialog(context, data) {
    context.commit('toggleRenameFileDialog', data);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
